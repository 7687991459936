import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { I18nService } from '@app/core';
import { AlertsService } from '@app/core/alerts/alerts.service';
import { TelegramApiService } from '@app/core/api/api.telegram';
import { ChannelService } from '@app/core/channel.service';
import { PHONE_REGEX } from '@app/core/config/regExValidators';
import { CrmService } from '@app/core/crm.service';
import { FeatureAccessService } from '@app/core/feature-access.service';
import { Response } from '@app/core/interfaces/api.response';
import { ProjectService } from '@app/core/project.service';
import { ToastType } from '@app/core/toasts/toast';
import { ToastService } from '@app/core/toasts/toast.service';
import { UtilsService } from '@app/core/utils.service';
import { WebsocketsService, WsEvent } from '@app/core/websockets.service';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

const CODE_REGEX = /^\d{5}$/u;

@Component({
  selector: 'app-telegram-setup',
  templateUrl: 'telegram-setup.component.html',
  styleUrls: ['telegram-setup.component.scss'],
  preserveWhitespaces: false,
})
export class TelegramSetupComponent implements OnInit, OnDestroy {
  @Input() public channel: any;
  @Input() public isEdit: boolean;
  @Output() public close: EventEmitter<boolean> = new EventEmitter();

  public channelData: UntypedFormGroup = new UntypedFormGroup({
    channelName: new UntypedFormControl('Telegram', [Validators.required]),
    groupChatAllowed: new UntypedFormControl(true),
  });

  public phoneNumberControl = new UntypedFormControl('', [Validators.required, Validators.pattern(PHONE_REGEX)]);
  public pinCodeFormControl = new UntypedFormControl('', [Validators.required, Validators.pattern(CODE_REGEX)]);
  public passwordFormControl = new UntypedFormControl('', Validators.required);

  public AUTH_TYPE = {
    QR: 'qr',
    PHONE: 'phone',
  };
  public selectedAuthType = this.AUTH_TYPE.PHONE;

  public STEPS = {
    SELECT_METHOD: 0,
    INPUT_NUMBER: 1,
    SCAN_QR: 2,
    INPUT_PINCODE: 3,
    CREATED: 4,
  };
  private _currentStep: number = this.STEPS.SELECT_METHOD;

  get currentStep(): number {
    return this._currentStep;
  }

  set currentStep(newValue: number) {
    this.apiError = '';
    this._currentStep = newValue;
  }

  public apiError: string;
  public inProgress: boolean = false;
  public removeInProgress: boolean = false;

  public channelActive: boolean;
  public currentChannelId: string;

  public isTwoFactorAuthentication: boolean;
  public qr: string;

  public isChannelUnpaid: boolean;
  private unsub$ = new Subject<void>();
  public language: any;
  public isLinkShared: boolean = false;

  get phone(): string {
    const phone = this.channel?.phoneNumber || this.phoneNumberControl.value;
    return phone ? this.formatPhoneNumber(phone) : '';
  }

  get channelStateDescription(): string {
    switch (this.channel?.state) {
      case 'pending': return this.language.telegram.statusPendingDescription;
      case 'need action': return this.language.telegram.statusNeedActionDescription;
      case 'error': return this.language.telegram.statusErrorDescription;
      default: return '';
    }
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private telegramApiService: TelegramApiService,
    private detector: ChangeDetectorRef,
    private alertsService: AlertsService,
    private channelService: ChannelService,
    private i18nService: I18nService,
    private featureAccessService: FeatureAccessService,
    private crmService: CrmService,
    private ws: WebsocketsService,
    private utils: UtilsService,
    private projectService: ProjectService,
    private toastService: ToastService,
    private zone: NgZone,
  ) {
    this.language = this.i18nService.translateService.translations[this.i18nService.language];
  }

  public ngOnInit() {
    this.featureAccessService.getAccessStatus('telegram', this.channel?.id)
      .pipe(takeUntil(this.unsub$))
      .subscribe((hasAccess) => {
        this.isChannelUnpaid = !hasAccess;
      });

    if (this.channel) {
      this.currentChannelId = this.channel.id;

      this.channelData.setValue({
        channelName: this.channel.name,
        groupChatAllowed: this.channel.groupChatAllowed ?? true,
      });
      this.phoneNumberControl.setValue(this.channel.phoneNumber);

      if (['active', 'notpaid'].includes(this.channel.state)) {
        this.currentStep = this.STEPS.CREATED;
      }

      if (['need action', 'error'].includes(this.channel.state) && this.channel.phoneNumber) {
        this.phoneNumberControl.disable();
      }
    }

    this.watchPhoneUpdate();
    this.watchAuthError();
    this.watchForChannelStatus();
  }

  ngOnDestroy() {
    this.unsub$.next();
    this.unsub$.complete();

    this.ws.removeListener(WsEvent.CHANNEL_UPDATED);
    this.ws.removeListener(WsEvent.CHANNEL_STATUS_UPDATE);
    this.ws.removeListener(WsEvent.ERROR_NOTIFICATION);
  }

  watchPhoneUpdate() {
    this.ws.on(WsEvent.CHANNEL_UPDATED, (_data) => {
      const data = this.utils.camelKeys(_data);

      if (data.projectId !== this.projectService.currentProject.id) {
        return;
      }

      if (data?.channelId !== this.currentChannelId) {
        return;
      }

      // т к callback-и сокет событий запускаются вне зоны, после установки значений view не обновляется
      // использование явного detectChanges ломает регистрацию formControl-ов из-за чего при их изменений также view не перерисовывается
      // поэтому приходиться явно выполнять эти команды внутри zone
      this.zone.run(() => {
        if (data.phoneNumber) {
          this.channel = {
            ...this.channel,
            phoneNumber: data.phoneNumber,
          };
        }
      });
    });
  }

  watchAuthError() {
    this.ws.on(WsEvent.ERROR_NOTIFICATION, (_data) => {
      const data = this.utils.camelKeys(_data);

      if (data.projectId !== this.projectService.currentProject.id) {
        return;
      }

      if (data?.channel.id !== this.currentChannelId) {
        return;
      }

      // т к callback-и сокет событий запускаются вне зоны, после установки значений view не обновляется
      // использование явного detectChanges ломает регистрацию formControl-ов из-за чего при их изменений также view не перерисовывается
      // поэтому приходиться явно выполнять эти команды внутри zone
      this.zone.run(() => {
        this.apiError = data.text;
      });
    });
  }

  public createInstance() {
    const goToNextStep = (id: string) => {
      this.currentChannelId = id;

      if (this.selectedAuthType === this.AUTH_TYPE.QR) {
        this.currentStep = this.STEPS.SCAN_QR;
        this.getQr();
      } else {
        if (this.channel?.phoneNumber) {
          this.submitPhone();
          this.currentStep = this.STEPS.INPUT_PINCODE;
        } else {
          this.currentStep = this.STEPS.INPUT_NUMBER;
        }
      }
    };

    if (this.currentChannelId) {
      goToNextStep(this.currentChannelId);
    } else {
      this.inProgress = true;
      this.telegramApiService
        .createInstance()
        .subscribe((res) => {
          this.inProgress = false;

          if (res.success) {
            goToNextStep(res.data.id);
          } else {
            this.apiError = res.errors[0].message;
          }
        });
    }
  }

  getQr() {
    this.inProgress = true;
    this.apiError = '';
    this.telegramApiService
      .getQrForAuth(this.currentChannelId)
      .subscribe((res) => {
        if (res.success) {
          this.qr = res.data.qrCode;
          const expireTimeout = res.data.expiresAt * 1000 - new Date().getTime();
          setTimeout(() => {
            this.qr = '';
          }, expireTimeout);
        } else {
          this.apiError = res.errors[0].message;
        }

        this.inProgress = false;
      });
  }

  watchForChannelStatus() {
    this.ws.on(WsEvent.CHANNEL_STATUS_UPDATE, (_data) => {
      const data = this.utils.camelKeys(_data);

      if (data.projectId !== this.projectService.currentProject.id) {
        return;
      }

      if (data?.channelId !== this.currentChannelId) {
        return;
      }

      // т к callback-и сокет событий запускаются вне зоны, после установки значений view не обновляется
      // использование явного detectChanges ломает регистрацию formControl-ов из-за чего при их изменений также view не перерисовывается
      // поэтому приходиться явно выполнять эти команды внутри zone
      this.zone.run(() => {
        if (['active', 'notpaid'].includes(data.state)) {
          this.currentStep = this.STEPS.CREATED;
          this.channelActive = true;
        }

        if (data.state === 'need extra auth') {
          this.currentStep = this.STEPS.INPUT_PINCODE;
          this.isTwoFactorAuthentication = true;
        }

        if (['need action', 'error'].includes(data.state)) {
          this.currentStep = this.STEPS.SELECT_METHOD;
          this.channelActive = false;
        }
      });
    });
  }

  public submitPhone(): void {
    if (this.phoneNumberControl.invalid || this.inProgress) {
      return;
    }
    const phone = this.phoneNumberControl.value;
    this.inProgress = true;

    this.telegramApiService
      .sendCodeForAuth(this.currentChannelId, phone)
      .pipe(takeUntil(this.unsub$))
      .subscribe((res) => {
        if (res.success) {
          this.currentStep = this.STEPS.INPUT_PINCODE;

          this.channel = {
            ...this.channel,
            phoneNumber: res.data.phoneNumber,
          };

          if (res.data.status === 'need extra auth') {
            this.isTwoFactorAuthentication = true;
          }
        } else {
          this.apiError = res.errors[0].message || 'Error, could not get pin :-(';
        }

        this.inProgress = false;
      });
  }

  public finishAuth() {
    if (this.inProgress) {
      return;
    }

    const data: Record<string, string> = {
      ...(this.selectedAuthType === this.AUTH_TYPE.PHONE ? {code: this.pinCodeFormControl.value} : {}),
      ...(this.isTwoFactorAuthentication ? { password: this.passwordFormControl.value } : {}),
    };

    this.apiError = '';
    this.inProgress = true;
    this.telegramApiService
      .finishAuth(this.currentChannelId, data)
      .pipe(takeUntil(this.unsub$))
      .subscribe((res) => {
        if (res.success) {
          if (res.data.status === 'active') {
            this.currentStep = this.STEPS.CREATED;
            this.channelActive = true;
          }

          if (res.data.status === 'need extra auth') {
            this.pinCodeFormControl.disable();
            this.isTwoFactorAuthentication = true;
          }
        } else {
          this.apiError = res.errors[0].message;
        }

        this.inProgress = false;
      });
  }

  public saveChannelData() {
    const data = {
      name: this.channelData.value.channelName,
      groupChatAllowed: this.channelData.value.groupChatAllowed,
    };
    this.inProgress = true;
    this.telegramApiService
      .updateTelegram(this.currentChannelId, data)
      .pipe(takeUntil(this.unsub$))
      .subscribe((response: Response) => {
        if (response.success) {
          this.close.emit();
        } else {
          this.apiError = response.errors[0].message;
        }

        this.inProgress = false;
      });
  }

  public backToSelectAuthMethod() {
    if (this.currentStep === this.STEPS.INPUT_NUMBER) {
      this.currentStep = this.STEPS.SELECT_METHOD;
      return;
    }

    this.inProgress = true;
    this.telegramApiService.logoutInstance(this.currentChannelId)
      .subscribe((res) => {
        if (res.success) {
          this.currentStep = this.STEPS.SELECT_METHOD;
        } else {
          this.apiError = res.errors[0].message;
        }

        this.inProgress = false;
      });
  }

  public removeTelegram() {
    let message = this.language.deleteChannelMessage;

    if (this.crmService.crms.find(crm => crm.type === 'moi_sklad')) {
      message += this.language.deleteChannelMessageWithSklad;
    }

    this.alertsService.confirmDelete({
      title: this.language.deleteChannelTitle,
      content: message,
      okButtonText: this.language.button.delete,
      cancelButtonText: this.language.button.cancel,
      okCallback: () => {
        this.removeInProgress = true;
        this.telegramApiService
          .removeTelegram({ channelId: this.currentChannelId })
          .pipe(takeUntil(this.unsub$))
          .subscribe(
            (response) => {
              if (response.success) {
                this.close.emit();
              } else {
                this.apiError = response.errors[0].message || 'Error, could not remove telegram channel';
              }
            },
            null,
            () => {
              this.removeInProgress = false;
            },
          );
      },
    });
  }

  formatPhoneNumber(phone: string): string {
    return `${phone.substr(0, 2)} (${phone.substr(2, 3)}) ${phone.substr(5, 3)}-${phone.substr(8, 2)}-${phone.substr(10)}`;;
  }

  onUpdateTariff(event: boolean) {
    if (!event) {
      this.close.emit();
      setTimeout(() => {
        this.featureAccessService.showDeclinePopup();
      }, 800);
    }
    this.isChannelUnpaid = !event;
  }

  setAuthType(newType: string) {
    this.selectedAuthType = newType;
  }

  getCode() {
    let request;

    this.inProgress = true;
    if (this.currentChannelId) {
      request = this.telegramApiService.getAuthLink(this.currentChannelId);
    } else {
      request = this.telegramApiService.createInstance()
        .pipe(
          switchMap((res) => {
            if (res.data.id) {
              this.currentChannelId = res.data.id;
              return this.telegramApiService.getAuthLink(res.data.id);
            } else {
              this.apiError = res.errors[0].message;
              this.inProgress = false;
            }
          }),
        );
    }

    request.subscribe((res) => {
      if (res.success) {
        this.inProgress = false;
        const domain = window.location.origin;
        const page = '/assets/auth-telegram/auth-telegram.html';

        const params =  new URLSearchParams();
        params.set('projectId', this.projectService.getCurrentProject().id);
        params.set('token', res.data.token);
        params.set('lang', this.i18nService.translateService.currentLang);

        if (this.isEdit && this.channel.phoneNumber) {
          params.set('phone', this.channel.phoneNumber);
        }

        const link = domain + page + '?' + params.toString();

        navigator.clipboard.writeText(link);
        this.toastService.addSimpleToast({
          title: 'toasts.done',
          text: 'whatsapp.linkCopied',
          type: ToastType.Success,
          time: 2000,
        });

        this.isLinkShared = true;
      }
    })
  }
}
