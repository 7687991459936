import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { MessageProvider } from '@app/core/interfaces/message';
import { ProjectService } from '@app/core/project.service';
import { TagsService } from '@app/core/tags.service';
import { WebsocketsService, WsEvent } from '@app/core/websockets.service';
import { TYPING_TIMEOUT } from '@app/core/config/timeouts';
import { UserService } from '@app/core/user.service';
import { I18nService } from '@app/core';
import { skip, takeUntil } from 'rxjs/operators';
import { AlertsService } from '@app/core/alerts/alerts.service';
import { DomSanitizer, SafeHtml, SafeStyle } from '@angular/platform-browser';
import { UtilsService } from '@app/core/utils.service';
import { Subject } from 'rxjs';
import { OperatorService } from '@app/core/operator.service';

@Component({
  selector: 'app-appeal-item',
  templateUrl: './appeal-item.component.html',
  styleUrls: ['./appeal-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false
})
export class AppealItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() appeal: any;
  @Input() class: any;
  @Input() hasLabel: boolean;
  @Input() isAppointed: boolean;
  @Input() avatar: string;
  @Input() avatarSize: string;
  @Input() avatarFontSize: string;
  @Input() isPerson: boolean;
  @Input() isActive: boolean;
  @Input() showTyping = true;
  @Input() failedMessage: string;
  @Output() appealDeleted: EventEmitter<string> = new EventEmitter();
  @Output() appealUpdated: EventEmitter<any> = new EventEmitter();

  public messageDate: string;
  public userName: string;
  public messenger: string;
  public message: any;
  public personColor: string;
  public personAnimal: string;
  public seen: boolean;
  public fromOperator = false;
  public fromOperatorText = '';
  public userTyping: boolean | any = false;
  private userTypingTimeout: any;
  private unsub$ = new Subject<void>();
  private deleteInProgress: boolean;
  private language: any;
  public isNewAppeal: boolean;
  public sendFailed: boolean;
  public clientName: string;
  public clientAvatar: string;
  public isTagsAvailable = this.tagsService.isTagsAvailable;

  public get countNewMessages(): number {
    const appeal = this.appeal.appeal || this.appeal;
    if (appeal.channelSpecific && appeal.channelSpecific.openedAppeals &&
      appeal.channelSpecific.openedAppeals.totalItems) {
      return appeal.channelSpecific.openedAppeals.totalItems || 0;
    } else {
      return appeal.countNewMessages;
    }
  }

  get personTags$() {
    const appeal = this.appeal.appeal || this.appeal;
    return this.tagsService.mapTagIdToTags(appeal.personTags || []);
  }

  constructor(
    private detector: ChangeDetectorRef,
    private ws: WebsocketsService,
    private profile: UserService,
    private alert: AlertsService,
    private i18nService: I18nService,
    private sanitizer: DomSanitizer,
    private utils: UtilsService,
    private operatorService: OperatorService,
    private tagsService: TagsService,
    private projectService: ProjectService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.language = this.i18nService.translateService.translations[this.i18nService.language];

    this.ws.onOperatorTyping()
      .pipe(
        takeUntil(this.unsub$),
      )
      .subscribe((_data: any) => {
        const data = this.utils.camelKeys(_data);

        if (data.projectId !== this.projectService.currentProject.id) {
          return;
        }

        const userId = this.userService.user.id;
        if (this.appeal && data.appealId === this.appeal.id && this.showTyping && data.person.id !== userId) {
          clearTimeout(this.userTypingTimeout);
          this.userTyping = {
            person: data.person.name,
          };
          if (!this.detector['destroyed']) {
            this.detector.detectChanges();
          }
          this.userTypingTimeout = setTimeout(() => {
            this.hideTyping();
          }, TYPING_TIMEOUT * 6);
        }
      });

    this.ws.on(WsEvent.USER_TYPING, (_data: any) => {
      const data = this.utils.camelKeys(_data);

      if (this.appeal && data.appealId === this.appeal.id && this.showTyping) {

        clearTimeout(this.userTypingTimeout);
        this.userTyping = {
          person: this.userName
        };

        this.detector.detectChanges();

        this.userTypingTimeout = setTimeout(() => {
          this.hideTyping();
        }, TYPING_TIMEOUT * 6);
      }
    });

    this.ws.on(WsEvent.PERSON_STOP_TYPING, (_data: any) => {
      const data = this.utils.camelKeys(_data);
      if (data.projectId !== this.projectService.currentProject.id) {
        return;
      }

      if (data.appealId === this.appeal.id) {
        clearTimeout(this.userTypingTimeout);
        this.hideTyping();
        this.detector.detectChanges();
      }
    });

    this.formatAppeal();
  }

  hideTyping() {
    this.userTyping = false;
    if (!this.detector['destroyed']) {
      this.detector.detectChanges();
    }
  }

  appealDelete(): void {
    if (this.deleteInProgress) {
      return;
    }
    const appealId = this.appeal.appeal.id || this.appeal.id;

    this.alert.confirm({
      title: 'appeal.confirmDelete.title',
      content: 'appeal.confirmDelete.content',
      okButtonText: this.language.button.delete,
      cancelButtonText: this.language.button.cancel,
      okCallback: () => {
        this.deleteInProgress = true;
        this.appealDeleted.emit(appealId);
      }
    });
  }

  formatAppeal() {
    const appeal = this.appeal.appeal || this.appeal;
    const appealFrom = appeal && appeal.from;
    const appealDate = appeal && appeal.date;
    const message = appeal.message || appeal.text || '';
    this.sendFailed = appeal.status && appeal.status.toString() === '20';
    this.message = this.bypassHtml(this.utils.parseMessage(message, false, false));
    this.clientName = appeal?.clientName && this.getFullName(appeal.clientId) || '';
    this.clientAvatar = appeal?.clientId && this.getAvatarUrl(appeal.clientId) || '';

    let messageType = appeal.messageType;

    // проверка на отсутствующий message нужна для email канала
    if (!message) {
      messageType = appeal.attachments?.length ? appeal.attachments[0].messageType : appeal.messageType;
    }

    if (messageType === 'image') {
      this.message = 'Sent you a photo';
    } else if (messageType === 'attachment' || !message) {
      this.message = 'Send you a file';
    } else if (messageType === 'gif') {
      this.message = 'Send you a GIF';
    }

    if (appeal.personId !== undefined && appeal.from && appeal.from.id !== undefined) {

      this.profile.getUserData().pipe(takeUntil(this.unsub$))
        .subscribe((userData: any) => {
          if (userData) {
            this.fromOperator = true;
            this.fromOperatorText = userData.id === appeal.from.id ? 'conversation.you' : appeal.from.name;

            if (appeal.provider === MessageProvider.Bot) {
              this.fromOperatorText = 'conversation.bot';
            }

            let messageType = appeal.messageType;

            // проверка на отсутствующий message нужна для email канала
            if (!message) {
              messageType = appeal.attachments?.length ? appeal.attachments[0].messageType : appeal.messageType;
            }

            if (messageType === 'image') {
              this.message = 'Photo';
            } else if (messageType === 'attachment') {
              this.message = 'File';
            } else if (messageType === 'gif') {
              this.message = 'GIF';
            } else if (messageType === 'video') {
              this.message = 'Video';
            } else if (messageType === 'audio') {
              this.message = 'Audio';
            }
          }

          this.detector.detectChanges();
        });
    } else {
      this.fromOperator = false;
      this.fromOperatorText = '';
    }

    this.seen = this.appeal.seen !== undefined ? this.appeal.seen : true;

    this.messenger = appeal.channelName || appealFrom && appealFrom.messenger || appeal.channel || 'online chat';
    if (!appeal.channelName) {
      this.messenger = this.messenger === 'mail' ? 'email' :
        this.messenger.replace('widget', 'online chat')
          .replace('web-', '')
          .replace('whatsapp_teletype', 'whatsapp');
    }

    this.isNewAppeal = !appeal.messageType;
    const instagramPost = appeal && appeal.channelSpecific && appeal.channelSpecific.instagramPost &&
      appeal.channelSpecific.instagramPost;
    const postUserName = instagramPost && instagramPost.userName;
    const postName = (instagramPost && instagramPost.caption) || postUserName || '';
    this.userName = postName || appeal.channelSpecific?.groupChat?.caption || appeal.personName || appeal.from.name;
    this.messageDate = appealDate && appealDate.date || appeal.lastActionAt;
    if (instagramPost && instagramPost.attachments && instagramPost.attachments[0]) {
      this.avatar = instagramPost.attachments[0].attachmentPreviewUrl;
    } else {
      this.avatar = appeal.personAvatar !== undefined ?
        appeal.personAvatar : this.isPerson && appeal.from.avatar || appeal.from.avatarDefault || '';
    }

    this.personColor = appeal.personColor || '';
    this.personAnimal = appeal.personAnimal || '';
    this.detector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    const appealChange = changes.appeal;
    if (appealChange !== undefined && appealChange.currentValue !== undefined) {
      this.appeal = appealChange.currentValue;
      this.formatAppeal();
    }
  }

  ngOnDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  bypassHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  sanitizeUrl(url: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }

  getAvatarUrl(clientId: string): string {
    return this.operatorService.getOperatorAvatar(clientId);
  }

  getFullName(clientId: string): string {
    return this.operatorService.getOperatorFullName(clientId);
  }
}
