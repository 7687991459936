import { Injectable } from '@angular/core';
import { TariffApiService } from '@app/core/api/api.tariff';
import { ChannelType } from '@app/core/channel.service';
import { TariffPackage, TariffPackageOption } from '@app/settings/tariff-packages/tariff-package';
import { finalize, takeUntil } from 'rxjs/operators';
import { Response } from '@app/core/interfaces/api.response';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ProjectService } from '@app/core/project.service';
import { CacheService } from '@app/core/cache.service';
import each from 'lodash-es/each';
import { WebsocketsService, WsEvent } from '@app/core/websockets.service';
import { UtilsService } from '@app/core/utils.service';
import { StorageService } from '@app/core/storage/storage.service';

export const LIMIT = 99999;

export interface Tariff {
  id: string;
  name: string;
  count: number;
  cost: any;
  quantityForFree: number;
  maxCount: number;
  description: string;
  disabled?: boolean;
  promoPrice: number | null;
}

export interface PromoEndDate {
  date: string;
  timezone: string;
}

export interface PromoDays {
  promoDaysGranted: number;
  promoDaysRemain: number;
}

export interface TariffResponse {
  options: any;
  balance: any;
  dailyPayment: number;
  dailyPaymentByPrice: number;
  paid: boolean;
  active: boolean;
  promisedPayment: number;
  promoDaysGranted: number;
  promoDaysRemain: number;
  paidEntities: any;
  packagePrice: TariffPackageOption[]
  packages: TariffPackage[],
  promoEndDate: PromoEndDate;
}

export interface TariffGroups {
  id: string;
  name?: string;
  extraName?: string;
  items: any;
}

export const NAMES = {
  operators: 'operators',
  onlineChat: 'online-chat',
  messengersButton: 'messengers-button',
  whatsapp: 'whatsapp',
  whatsappEdna: 'whatsapp-edna',
  whatsappFree: 'whatsapp-free',
  whatsapp_teletype: 'whatsapp',
  telegramBot: 'telegram-bot',
  vk: 'vk',
  vkDirect: 'vk-direct',
  facebook: 'facebook',
  viber: 'viber',
  avito: 'avito',
  email: 'email',
  instagramDirect: 'instagram-direct',
  instagramDirectBusiness: 'instagram-direct-business',
  instagramComments: 'instagram-comments',
  instagramCommentsBusiness: 'instagram-comments-business',
  statistic: 'statistic',
  messagesTemplates: 'messages-templates',
  amoCrm: 'amo-crm',
  telegramNotifier: 'telegram-notifier',
  telegram: 'telegram',
  publicApi: 'public-api',
  brandingLink: 'branding-link',
  whatsappPhone: 'whatsapp-phone',
  bitrix24: 'bitrix24',
  yclients: 'yclients',
  sbercrm: 'sber-crm',
  roistat: 'roistat',
  groups: 'groups',
  categories: 'categories',
  tags: 'tags',
  sipuni: 'sipuni',
};

export const MATCH_CHANNEL_TYPE_TO_TARIFF_OPTION: Record<ChannelType, string> = {
  [ChannelType.InstagramDirect]: 'instagramDirect',
  [ChannelType.InstagramDirectBusiness]: 'instagramDirectBusiness',
  [ChannelType.InstagramComments]: 'instagramComments',
  [ChannelType.InstagramCommentsBusiness]: 'instagramCommentsBusiness',
  [ChannelType.WhatsappTeletype]: 'whatsapp',
  [ChannelType.WhatsappEdna]: 'whatsapp-edna',
  [ChannelType.TelegramBot]: 'telegramBot',
  [ChannelType.Telegram]: 'telegram',
  [ChannelType.Widget]: '', // каналы этого типа тарифицируются либо опцией onlineChat, либо messengersButton
  [ChannelType.OnlineChat]: 'onlineChat', // каналы этого типа тарифицируются либо опцией onlineChat, либо messengersButton
  [ChannelType.MessengerButton]: 'messengersButton', // каналы этого типа тарифицируются либо опцией onlineChat, либо messengersButton
  [ChannelType.VkGroup]: 'vk',
  [ChannelType.VkDirect]: 'vkDirect',
  [ChannelType.Avito]: 'avito',
  [ChannelType.Viber]: 'viber',
  [ChannelType.Mail]: 'email',
  [ChannelType.Facebook]: 'facebook',
};

export const CHANNEL_LIST = ['email', 'facebook', 'instagramComments', 'instagramCommentsBusiness', 'instagramDirectBusiness',
  'instagramDirect', 'onlineChat', 'telegram', 'telegramBot', 'viber', 'vk', 'vkDirect', 'avito', 'whatsapp', 'whatsappEdna', 'whatsappFree',
  'messengersButton'];

export const TARIFFS_LIST = [
  {
    id: 'main',
    items: [
      {
        id: 'operators',
        count: 0,
        cost: {},
        quantityForFree: 1,
        maxCount: LIMIT,
      },
      {
        id: 'onlineChat',
        count: 0,
        cost: {},
        quantityForFree: 1,
        maxCount: LIMIT,
      },
      {
        id: 'messengersButton',
        count: 0,
        cost: {},
        quantityForFree: 1,
        maxCount: 1,
      },
      {
        id: 'vk',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: LIMIT,
      },
      {
        id: 'vkDirect',
        translationKey: 'vk-direct',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: LIMIT,
      },
      // {
      //   id: 'whatsappFree',
      //   count: 0,
      //   cost: {},
      //   quantityForFree: 1,
      //   maxCount: 1,
      // },
      {
        id: 'whatsapp',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: LIMIT,
      },
      {
        id: 'whatsappEdna',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: LIMIT,
      },
      {
        id: 'telegram',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: LIMIT,
      },
      {
        id: 'telegramBot',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: LIMIT,
      },
      {
        id: 'instagramCommentsBusiness',
        translationKey: 'instagram-comments',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: LIMIT,
      },
      {
        id: 'instagramDirectBusiness',
        translationKey: 'instagram-direct-business',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: LIMIT,
      },
      {
        id: 'facebook',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: LIMIT,
      },
      {
        id: 'viber',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: LIMIT,
      },
      {
        id: 'avito',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: LIMIT,
      },
      {
        id: 'email',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: LIMIT,
        disabled: false,
      },
      // {
      //   id: 'instagramDirect',
      //   translationKey: 'instagram-direct',
      //   count: 0,
      //   cost: {},
      //   quantityForFree: 0,
      //   maxCount: LIMIT,
      //   disabled: false,
      // },
      // {
      //   id: 'instagramComments',
      //   translationKey: 'instagram-comments',
      //   count: 0,
      //   cost: {},
      //   quantityForFree: 0,
      //   maxCount: LIMIT,
      //   disabled: environment && environment.features && !environment.features.channels['instagramComments']
      // }
    ],
  },

  {
    id: 'additional',
    name: 'additionalExtra',
    items: [
      {
        id: 'statistic',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: 1,
      },
      {
        id: 'messagesTemplates',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: 1,
      },
      {
        id: 'amoCrm',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: 1,
      },
      {
        id: 'telegramNotifier',
        count: 0,
        cost: {},
        quantityForFree: 1,
        maxCount: 1,
      },
      {
        id: 'bitrix24',
        translationKey: 'bitrixCrm',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: 1,
      },
      {
        id: 'yclients',
        translationKey: 'yclientsCrm',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: 1,
        disabled: true,
      },
      {
        id: 'sberCrm',
        translationKey: 'sberCrm',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: 1,
      },
      {
        id: 'sipuni',
        translationKey: 'sipuni',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: 1,
      },
      {
        id: 'publicApi',
        translationKey: 'public-api',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: 1,
      },
      {
        id: 'roistat',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: 1,
      },
      {
        id: 'brandingLink',
        translationKey: 'branding-link',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: 1,
      },
      {
        id: 'whatsappPhone',
        translationKey: 'whatsapp-phone',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: LIMIT,
      },
      {
        id: 'groups',
        translationKey: 'groups',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: 1,
      },
      {
        id: 'categories',
        translationKey: 'categories',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: 1,
      },
      {
        id: 'tags',
        translationKey: 'tags',
        count: 0,
        cost: {},
        quantityForFree: 0,
        maxCount: 1,
      },
    ],
  },
];

const HOUR = 1000 * 60 * 60; // ms

export enum MonthLength {
  quarter = 3,
  halfYear = 6,
  year = 12,
}

export enum Percent {
  month = 0,
  quarter = 0.05,
  halfYear = 0.10,
  year = 0.25,
}

export enum Days {
  month = 30,
  quarter = 90,
  halfYear = 180,
  year = 360,
}

export enum PercentText {
  quarter = '5%',
  halfYear = '10%',
  year = '25%',
}

@Injectable({
  providedIn: 'root',
})
export class TariffService {
  public isPublic = false;
  public tariffsCollection: TariffResponse;
  public saveInProgress: boolean;
  public selectedPeriod = 'month';
  public totalPaySum: number;
  private _balance: number;
  private _promisedPayment: number;
  private _dailyPayment: number;
  private _dailyPaymentByPrice: number;
  private _availableDays: number;
  private _paidEntities: any;
  private _paid: boolean;
  private _active: boolean;
  private _promoDays: PromoDays;
  private _promoEndDate: PromoEndDate;
  private unsub$ = new Subject<void>();

  public tariffs$: BehaviorSubject<any> = new BehaviorSubject(null);
  public isTariffsLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private tariffPackageOptions: BehaviorSubject<TariffPackageOption[]> = new BehaviorSubject([]);
  private tariffPackage: BehaviorSubject<TariffPackage[]> = new BehaviorSubject([]);

  private paidEntitiesSubject = new BehaviorSubject<any>(undefined);
  private balanceSubject = new BehaviorSubject<number>(undefined);
  private promisedPaymentSubject = new BehaviorSubject<number>(undefined);
  private dailyPaymentSubject = new BehaviorSubject<number>(undefined);
  private dailyPaymentByPriceSubject = new BehaviorSubject<number>(undefined);
  private availableDaysSubject = new BehaviorSubject<number>(undefined);
  private subjectPromotionPopUp = new BehaviorSubject<boolean>(false);
  private paidSubject = new BehaviorSubject<boolean>(undefined);
  private activeSubject = new BehaviorSubject<boolean>(undefined);
  private promoDaysSubject = new BehaviorSubject<PromoDays>(undefined);
  private promoEndDateSubject = new BehaviorSubject<PromoEndDate>({date: '', timezone: ''});
  private requestPending: boolean;
  private total: number;

  get isTariffsLoaded$() {
    return this.isTariffsLoaded.asObservable();
  }

  get paid(): boolean {
    return this._paid;
  }

  set paid(_paid: boolean) {
    this._paid = _paid;
    this.paidSubject.next(this._paid);
  }

  get active(): boolean {
    return this._active;
  }

  set active(_active: boolean) {
    this._active = _active;
    this.activeSubject.next(this._active);
  }

  get promoDays(): PromoDays {
    return this._promoDays;
  }

  set promoDays(_promoDays: PromoDays) {
    this._promoDays = _promoDays;
    this.promoDaysSubject.next(this._promoDays);
  }

  get promoEndDate(): PromoEndDate {
    return this._promoEndDate;
  }

  set promoEndDate(_promoEndDate: PromoEndDate) {
    this._promoEndDate = _promoEndDate;
    this.promoEndDateSubject.next(this._promoEndDate);
  }

  get tariffs(): any {
    return this.tariffs$.getValue();
  }

  set tariffs(tariffs: any) {
    this.tariffs$.next(this.fillTariffs(tariffs));
  }

  set packageOptions(packageOptions: TariffPackageOption[]) {
    this.tariffPackageOptions.next(this.fillTariffs(packageOptions));
  }

  set packages(packages: TariffPackage[]) {
    this.tariffPackage.next(this.fillTariffs(packages));
  }

  get paidEntities(): any {
    return this._paidEntities;
  }

  set paidEntities(paidEntities: any) {
    this._paidEntities = paidEntities;
    this.paidEntitiesSubject.next(this._paidEntities);
  }

  get balance(): number {
    return this._balance;
  }

  get promisedPayment(): number {
    return this._promisedPayment;
  }

  set balance(balance: number) {
    this._balance = balance;
    this.balanceSubject.next(this._balance);
  }

  set promisedPayment(payment: number) {
    this._promisedPayment = payment;
    this.promisedPaymentSubject.next(this._promisedPayment);
  }

  get dailyPayment(): number {
    return this._dailyPayment;
  }

  set dailyPayment(days: number) {
    this._dailyPayment = days;
    this.dailyPaymentSubject.next(this._dailyPayment);
  }

  get dailyPaymentByPrice(): number {
    return this._dailyPaymentByPrice;
  }

  set dailyPaymentByPrice(days: number) {
    this._dailyPaymentByPrice = days;
    this.dailyPaymentByPriceSubject.next(this._dailyPaymentByPrice);
  }

  get availableDays(): number {
    return this._availableDays;
  }

  set availableDays(days: number) {
    this._availableDays = days;
    this.availableDaysSubject.next(this._availableDays);
  }

  public showPromotionPopUp() {
    this.subjectPromotionPopUp.next(true);
  }

  public subscribePromotionPopUp(): Observable<any> {
    return this.subjectPromotionPopUp.asObservable();
  }

  constructor(
    private api: TariffApiService,
    private projectService: ProjectService,
    private cacheService: CacheService,
    private ws: WebsocketsService,
    private utils: UtilsService,
    private storage: StorageService,
  ) {

    this.projectService.isProjectsLoaded$.pipe().subscribe(isLoaded => {
      if (isLoaded) {
        this.runUpdate();
      }
    });

    this.ws.on(WsEvent.TARIFF_UPDATED, (_data: any) => {
      const data = this.utils.camelKeys(_data);
      if (data.projectId !== this.projectService.currentProject.id) {
        return;
      }
      this.loadTariffs();
    });
  }

  private fillTariffs(tariffs: any) {
    tariffs = tariffs || {};
    for (const property in tariffs) {
      if (tariffs.hasOwnProperty(property)) {
        for (const _key in tariffs[property].price) {
          if (tariffs[property].price.hasOwnProperty(_key)) {
            for (let i = 1; i <= Number(_key); i++) {
              if (!tariffs[property].cost) {
                tariffs[property].cost = {};
              }
              tariffs[property].cost[i] = i < Number(_key) ? 0 : tariffs[property].price[_key] / 100;
            }
          }
        }

        const key = Number(Object.keys(tariffs[property].price)[0]);
        tariffs[property].quantityForFree = 0;
        for (let i = 1; i <= key; i++) {
          if (tariffs[property].cost[i] === 0) {
            tariffs[property].quantityForFree += 1;
          }
        }
      }
    }

    return tariffs;
  }

  public loadTariffs() {
    if (this.isPublic) {
      this.api.getPublicTariffView()
        .pipe(takeUntil(this.unsub$))
        .subscribe((response: Response) => {
          this.handleLoadTariff(response);
        }, () => {}, () => {
          this.requestPending = false;
        });
    } else if (!this.storage.currentProjectId) {
      if (!this.requestPending) {
        this.requestPending = true;
        this.projectService.isProjectsLoaded$.pipe().subscribe(isLoaded => {
          if (isLoaded) {
            this.loadProjectTariffs();
          }
        });
      }
    } else {
      this.loadProjectTariffs();
    }
  }

  private loadProjectTariffs() {
    this.api.getTariffView().pipe(
      finalize(() => {
        this.isTariffsLoaded.next(true);
        this.requestPending = false;
      }),
      takeUntil(this.unsub$)
    ).subscribe((response: Response) => {
      this.handleLoadTariff(response);
    });
  }

  public handleLoadTariff(response: Response) {
    if (response && response.success) {
      this.tariffsCollection = response.data;
      this.paidEntities = this.tariffsCollection.paidEntities;
      this.balance = this.tariffsCollection.balance.total / 100;
      this.promisedPayment = this.tariffsCollection.promisedPayment / 100;
      this.dailyPayment = (this.tariffsCollection.dailyPayment || 0) / 100;
      this.dailyPaymentByPrice = (this.tariffsCollection.dailyPaymentByPrice || 0) / 100;
      // eslint-disable-next-line no-bitwise
      this.availableDays = Math.round((this.balance + this.promisedPayment) / this.dailyPayment | 0) || 0;
      this.tariffs = this.tariffsCollection.options;
      this.packageOptions = this.tariffsCollection.packagePrice;
      this.packages = this.tariffsCollection.packages;
      this.paid = this.tariffsCollection.paid;
      this.active = this.tariffsCollection.active;
      this.promoDays = {
        promoDaysGranted: this.tariffsCollection.promoDaysGranted,
        promoDaysRemain: this.tariffsCollection.promoDaysRemain,
      };
      this.promoEndDate = this.tariffsCollection.promoEndDate;
    }
  }

  public getTariffs(): Observable<any> {
    return this.tariffs$.asObservable();
  }

  public updateTariffs() {
    this.loadTariffs();
  }

  public getBalance(): Observable<number> {
    return this.balanceSubject.asObservable();
  }

  public getPromisedPayment(): Observable<number> {
    return this.promisedPaymentSubject.asObservable();
  }

  public getDailyPayment(): Observable<number> {
    return this.dailyPaymentSubject.asObservable();
  }

  public getDailyPaymentByPrice(): Observable<number> {
    return this.dailyPaymentByPriceSubject.asObservable();
  }

  public getAvailableDays(): Observable<number> {
    return this.availableDaysSubject.asObservable();
  }

  public getPromoDays(): Observable<PromoDays> {
    return this.promoDaysSubject.asObservable();
  }

  public getPromoEndDate(): Observable<PromoEndDate> {
    return this.promoEndDateSubject.asObservable();
  }

  public getTariffPackagesOptions(): Observable<TariffPackageOption[]> {
    return this.tariffPackageOptions.asObservable();
  }

  public getTariffPackages(): Observable<TariffPackage[]> {
    return this.tariffPackage.asObservable();
  }

  public getPaidState(): Observable<boolean> {
    return this.paidSubject.asObservable();
  }

  public getActiveState(): Observable<boolean> {
    return this.activeSubject.asObservable();
  }

  public getEntitiesList(): Observable<boolean> {
    return this.paidEntitiesSubject.asObservable();
  }

  public getPrice(tariff: Tariff) {
    const lastIndex = tariff && tariff.cost && Object.keys(tariff.cost).length;
    if (!lastIndex) {
      return 0;
    }
    return tariff.cost[tariff.count] ? tariff.cost[tariff.count] * 30 : tariff.cost[lastIndex] * 30;
  }

  public buyTariffPackage(packageCode: number) {
    return this.api.bayTariffPackage(packageCode);
  }

  public saveTariff(data: any) {
    if (!this.saveInProgress && data) {
      this.saveInProgress = true;

      return new Observable((observer) => {
        this.api.postTariffManage({ options: data }).pipe(takeUntil(this.unsub$))
          .subscribe((response: Response) => {
            if (response && response.success) {
              const _data = response.data;
              this.paidEntities = _data.paidEntities;
              this.tariffs = _data.options;
              this.balance = _data.balance.total / 100;
              this.promisedPayment = _data.promisedPayment / 100;
              this.dailyPayment = _data.dailyPayment / 100;
              this.dailyPaymentByPrice = _data.dailyPaymentByPrice / 100;
              this.paid = _data.paid;
              this.active = _data.active;
              // eslint-disable-next-line no-bitwise
              this.availableDays = Math.round((this.balance + this.promisedPayment) / this.dailyPayment | 0) || 0;
            }
            observer.next(response);
          }, () => {}, () => {
            this.saveInProgress = false;
            observer.complete();
          });
      });
    }
  }

  public runUpdate() {
    const currentDay = new Date().getDay();
    const startDay = Number(this.cacheService.get('currentDay')) || currentDay;
    if (currentDay !== startDay) {
      this.loadTariffs();
      this.cacheService.set('currentDay', currentDay);
    } else {
      setTimeout(() => {
        this.runUpdate();
      }, HOUR);
    }
  }

  public delayedUpdate(): void {
    this.loadTariffs();
    setTimeout(() => {
      this.loadTariffs();
    }, 10000);
  }

  public getDayText(availableDays: number) {
    let dayText; let
      i;
    const list = ['день', 'дня', 'дней'];
    availableDays %= 100;
    if (availableDays >= 11 && availableDays <= 19) {
      dayText = list[2];
    } else {
      i = availableDays % 10;
      switch (i) {
        case (1): dayText = list[0]; break;
        case (2):
        case (3):
        case (4): dayText = list[1]; break;
        default: dayText = list[2];
      }
    }
    return dayText;
  }

  public getSum(tariff: Tariff, isTotalCalc: boolean = false) {
    let sum = 0;
    if (tariff.count > 0) {
      const count = tariff.count - tariff.quantityForFree;
      for (let i = 0; i < count; i++) {
        sum += this.getPrice(tariff);
      }
    }
    if (sum > 0 && MonthLength[this.selectedPeriod]) {
      sum *= MonthLength[this.selectedPeriod];
    }
    return isTotalCalc ? sum : Number(sum.toFixed(2));
  }

  public calcTotal() {
    let total = 0;
    each(this.tariffs, (tariff) => {
      each(tariff.items, (item) => {
        total += this.getSum(item);
      });
    });
    return Number(total.toFixed(2));
  }

  public getAllowChannelsId() {
    if (!this.paidEntities) {
      return;
    }
    let allowChannelsId = [];
    each(CHANNEL_LIST, (channel) => {
      if (this.paidEntities[channel] && this.paidEntities[channel].length > 0) {
        allowChannelsId = allowChannelsId.concat(this.paidEntities[channel]);
      }
    });
    return allowChannelsId;
  }

  public getAllowOperatorsId(): Array<[]> {
    let allowOperatorsId = [];
    if (!this.paidEntities) {
      return allowOperatorsId;
    }

    allowOperatorsId = allowOperatorsId.concat(this.paidEntities.operators);
    return allowOperatorsId;
  }
}
