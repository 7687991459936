import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { GoogleTagService } from '@app/core/gtag.service';
import { UserService } from '@app/core/user.service';
import { ONLY_LETTERS_REGEX, PHONE_REGEX } from '@app/core/config/regExValidators';
import { YaglaAnalyticsService } from '@app/core/yagla.service';
import each from 'lodash-es/each';
import { takeUntil, filter, take } from 'rxjs/operators';
import { AlertsService } from '@app/core/alerts/alerts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CookiesService } from '@app/core/cookies.service';
import { TariffService } from '@app/core/tariff.service';
import { environment } from '@env/environment';
import { ShellResolver } from '@app/core/shell/shell.resolver';
import { RegistrationApiService } from '@app/core/api/api.registration';
import { UserData } from '@app/core/api/api.profile';
import { I18nService } from '@app/core';
import { MetrikaService, ANALYTICS_EVENT } from '@app/core/metrika.service';
import { FaceBookPixelService } from '@app/core/fbpixel.service';
import { LocationService } from '@app/core/location.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AmplitudeService } from '@app/core/amplitude.service';
import { Subject } from 'rxjs';
import { VkAnalyticsService } from '@app/core/vk.service';
import { MailRuAnalyticsService } from '@app/core/mail-ru.service';
import { ProjectService } from '@app/core/project.service';

const TARIFF_KEY = 'tariffConfiguration';

enum PriorMessengerOptions {
  WhatAapp = 'whatsapp',
  Telegram = 'telegram',
  SMS = 'sms',
}

@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.scss'],
})
export class CompleteComponent implements OnInit, OnDestroy {
  public errorsList: any = {};
  public error: string;
  public hasProject: boolean;
  public completeRegistrationForm: UntypedFormGroup;
  public isLoading = false;
  public usersCounts = [
    {
      display: '1-10',
      value: 10,
    },
    {
      display: '10-30',
      value: 20,
    },
    {
      display: '30-60',
      value: 30,
    },
    {
      display: '60-100',
      value: 40,
    },
    {
      display: '100+',
      value: 50,
    },
  ];
  public companyRoles: any;
  public companyScopes: any;
  public hasExternalAuth: any = false;
  public isUserLoaded = false;
  private unsub$ = new Subject<void>();

  constructor(
    private meta: Meta,
    private api: RegistrationApiService,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private deviceService: DeviceDetectorService,
    private alerts: AlertsService,
    private detector: ChangeDetectorRef,
    private router: Router,
    private cookies: CookiesService,
    private tariffService: TariffService,
    private shellResolver: ShellResolver,
    private i18nService: I18nService,
    private titleService: Title,
    private route: ActivatedRoute,
    private metrika: MetrikaService,
    private fbPixel: FaceBookPixelService,
    private locationService: LocationService,
    private amplitudeService: AmplitudeService,
    private gtag: GoogleTagService,
    private vk: VkAnalyticsService,
    private yagla: YaglaAnalyticsService,
    private mailRu: MailRuAnalyticsService,
    private projectService: ProjectService,
  ) {
    this.meta.updateTag({
      content: 'width=device-width, initial-scale=1',
      name: 'viewport',
    });
  }

  ngOnInit() {
    this.createForm();
    const title = this.route.snapshot.data['title'];

    if (this.route.snapshot.queryParams.oauth === 'elama-auth') {
      this.isUserLoaded = true;
      this.removeUnusedElamaFields();
    }

    this.i18nService.translateService
      .getTranslation(this.i18nService.language)
      .pipe(take(1))
      .subscribe((language: any) => {
        if (language) {
          this.companyScopes = language.registration.companyScopes;
          this.companyRoles = language.registration.companyRoles;
          if (title) {
            this.titleService.setTitle(language.route[title.toLowerCase()]);
          }
        }
      });

    this.userService
      .getUserData()
      .pipe(takeUntil(this.unsub$))
      .subscribe((data: UserData) => {
        this.isUserLoaded = true;

        if (!data) {
          return;
        }

        if (data.status === 20) {
          this.goHome();
          return;
        }

        if (data.hasExternalAuth) {
          this.removeUnusedElamaFields();
        }


        const tariffConfiguration = this.cookies.get(TARIFF_KEY);
        if (tariffConfiguration) {
          const tariffInfo = JSON.parse(tariffConfiguration);

          this.cookies.set(TARIFF_KEY, null, null, '/', environment.cookiesOrigin);

          this.tariffService
            .saveTariff(tariffInfo)
            .pipe(takeUntil(this.unsub$))
            .subscribe();
        }
      });
  }

  ngOnDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  removeUnusedElamaFields() {
    this.hasExternalAuth = true;
    this.completeRegistrationForm.removeControl('phone');
    this.completeRegistrationForm.removeControl('priorMessenger');
    this.completeRegistrationForm.updateValueAndValidity();
    this.detector.markForCheck();
  }


  completeRegistration(): void {
    this.validateOnSubmit();

    if (
      this.completeRegistrationForm.valid === false ||
      this.completeRegistrationForm.get('name').value.trim() === ''
    ) {
      return;
    }

    this.isLoading = true;
    const data = {
      ...this.completeRegistrationForm.value,
    };

    this.api
      .initClient(data)
      .pipe(takeUntil(this.unsub$))
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.amplitudeService.track('exernal_id', {exernal_id: response.data.clientId});
            this.userService.getUser();
            this.projectService.updateProject();
            window.scrollTo(0, 0);
            this.sendAnalyticsEvent(ANALYTICS_EVENT.PROFILE_CONFIRM);
            // т к this.goHome() перезагружает страницу и аналитика не успевает отправиться на сервер
            // должно устраниться если в goHome перейти на router.naviagte
            setTimeout(() => { this.goHome(); }, 1000);
          } else {
            this.isLoading = false;
            this.showError(response);
          }
        },
        () => {},
        () => {
          this.isLoading = false;
          this.detector.detectChanges();
        }
      );

    this.alerts.removeAll();
    this.error = '';
  }

  handleFieldBlur(field: string) {
    const fieldState = this.completeRegistrationForm.get(field);
    this.completeRegistrationForm.controls[field].setValue(this.completeRegistrationForm.value[field].trim());
    this.errorsList[field] = !fieldState.valid && fieldState.value.trim() !== '';
  }

  validateOnSubmit() {
    const form = this.completeRegistrationForm;
    each(form.controls, (control, name) => {
      this.errorsList[name] = control.errors !== null;
    });
  }

  private createForm(): void {
    this.completeRegistrationForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.pattern(ONLY_LETTERS_REGEX)])],
      phone: ['', Validators.compose([Validators.required, Validators.pattern(PHONE_REGEX)])],
      priorMessenger: [PriorMessengerOptions.WhatAapp, Validators.required],
      companyName: ['', Validators.required],
      usersCount: [30, Validators.required],
      companyRole: [null, Validators.required],
      companyScope: [null, Validators.required],
    });
  }

  showErrorToView(name: string): boolean {
    return (
      this.errorsList &&
      this.errorsList[name] &&
      (this.completeRegistrationForm.get(name).hasError('pattern') ||
        this.completeRegistrationForm.get(name).hasError('required'))
    );
  }

  showError(response: any) {
    if (!response || !response.errors || !response.errors.length) {
      return;
    }
    this.error = response.errors[0].message;
  }

  setOperatorState(projectOperator: boolean): void {
    this.completeRegistrationForm.controls.projectOperator.setValue(projectOperator);

    this.detector.detectChanges();
  }

  goHome() {
    // TODO: сделать тут переход через router.navigate (просто взять и поменять тут не получится, так как приложение ломается)
    window.location.href = this.deviceService.isMobile()
      ? `${environment.assetsUrl}registration/mobile-warn`
      : `${environment.assetsUrl}conversations/open/open`;
  }

  sendAnalyticsEvent(event: string) {
    this.metrika.goal(event);
    this.gtag.track(event);
    this.fbPixel.track(event);
    this.vk.track(event);
    this.yagla.track(event);
    this.mailRu.track(event);
  }

  setUsersCount(size: number): void {
    this.completeRegistrationForm.controls.usersCount.setValue(size);

    this.detector.detectChanges();
  }

  public onCompanyScopeSelect(): void {
    this.errorsList.companyScope = false;
  }

  public onCompanyRoleSelect(): void {
    this.errorsList.companyRole = false;
  }

  public goToLanding() {
    this.locationService.goToLanding();
  }

  PriorMessengerOptions = PriorMessengerOptions;
}
