import {
  PasswordKeyData,
} from '@app/core/interfaces/api.response';
import { environment } from '@env/environment';
import { SetPasswordData } from '@app/core/api/api.login';
import { AppealHistoryContext } from '@app/core/api/api.conversation';
import { RemoveByIdContext } from '@app/core/api/api.project';
import { ChannelIdContext } from '@app/core/api/api.channel';
import { AppealSetOperatorContext, PersonBannedState, PersonSetResponsibleContext } from '@app/core/api/api.person';
import { each, snakeCase } from 'lodash-es';

export const routes = {
  seen: (appealId: string) => `appeals/${appealId}/seen`,
  seenPost: (postId: string) => `instagram-comments-business/close-all-appeals/${postId}`,
  closeThread: (appealId: string) => `instagram-comments-business/close-appeal/${appealId}`,
  status: (appealId: string) => `appeal/close/${appealId}`,
  setRead: (appealId: string) => `appeal/answered/${appealId}`,
  confirmEmail: (id: string) => `registration/confirm/${id}`,
  completeRegistration: (id: string) => `registration/completion/${id}`,
  invite: (id: string) => `client/invite/${id}`,
  initClient: 'client/setup',
  auth: 'client/login',
  logout: 'client/logout',
  register: 'registration/init',
  profile: 'client/view',
  sipConfig: 'sip-services',
  sipCall: (id: string) => `sip-service/call/${id}`,
  channels: 'channels',
  operators: 'clients',
  setRoleForClient: (clientId: string) => `client/set-role/${clientId}`,
  responsibleOperatorsConfig: 'project/responsible-operators-config',
  updateResponsibleOperators: 'project/update-responsible-operators',
  /** @deprecated */
  createWhatsappTeletypeClassic: 'whatsapp-teletype/create',
  createWhatsappPhonelessTeletype: 'whatsapp-teletype/create-phoneless',

  elamaGetLink: 'auth-elama/config',
  elamaAuth: 'auth-elama/authorize',

  whatsappEdnaCreate: 'whatsapp-edna/create',
  whatsappEdnaGetConfig: (channelId: string) => `whatsapp-edna/connection-data/${channelId}`,
  whatsappEdnaSetParameters: (channelId: string) => `whatsapp-edna/update-connection/${channelId}`,
  whatsappEdnaUpdateToken: (channelId: string) => `whatsapp-edna/update-token/${channelId}`,
  whatsappEdnaDelete: (channelId: string) => `whatsapp-edna/delete/${channelId}`,
  whatsappEdnaLoadTemplates: (channelId: string) => `whatsapp-edna/import-templates/${channelId}`,

  // универсальный метод. Принимает на вход три массива insert/update/delete
  customFieldsUpdateSettings: 'person-custom-fields/update-settings',

  updateProfile: 'client/update',
  resend: 'client/restore',
  testImap: 'imap/test',
  createImap: 'imap/create',
  updateImap: (id: string) => `imap/update/${id}`,
  removeImap: (id: string) => `imap/delete/${id}`,
  createWidget: 'widget/create',
  sendWidgetCodeViaEmail: (channelId: string) => `web-widget/webmaster-email-code/${channelId}`,
  getProjects: 'projects',
  createError: 'project/create-error',
  getUserAvatar: 'client/view-avatar',
  createProject: 'project/create',
  updateSoundSettings: 'project/update-sound-settings',
  inviteOperator: 'project/invite',
  clientRestore: 'client/restore',
  appealsRemapping: 'appeals/remapping',
  // ApiPrefixInterceptor don't catch query for UploadInput
  uploadUrl: (isAbs = false) => (isAbs ? `${environment.apiUrl}/v${environment.apiVersion}/attachment`
    : 'attachment'),
  appealsList: (c: string) => `appeals/list?${c}`,
  getAppealById: (appealId: string) => `appeal/view/${appealId}`,
  appealHistory: (c: AppealHistoryContext) => {
    if (c.from === 0 && c.to <= 0) {
      delete c.from;
      delete c.to;
    }
    const hasMoreParams = Object.keys(c).length > 1;
    let params = `appeals/${c.id}${hasMoreParams ? '?' : ''}`;

    each(Object.keys(c), (key) => {
      if (key !== 'id') {
        const hasQuestion = params.slice(-1) === '?';
        let value = c[key];
        if (typeof value === 'boolean') {
          value = value ? 1 : 0;
        }
        const param = `${snakeCase(key)}=${value}`;
        params += hasQuestion ? param : `&${param}`;
      }
    });
    return params;
  },
  batchAppealHistory: 'appeal/batch-appeals-cache',
  appealSetRandom: 'client/set-random-appeal',
  appealSetClient: (call: AppealSetOperatorContext) => `appeal/set-operator/${call.id}`,
  personSetResponsibleOperator: (call: PersonSetResponsibleContext) => `person/set-responsible-operator/${call.personId}`,
  getPersonCustomFieldsValue: (id: string) => `person/get-custom-fields/${id}`,
  setPersonCustomFieldsValue: (id: string) => `person/set-custom-fields/${id}`,
  personSetTag: (personId: string) => `person/add-tag/${personId}`,
  personRemoveTag: (personId: string) => `person/remove-tag/${personId}`,
  appealSetCategory: (appealId: string) => `appeal/set-category/${appealId}`,
  appealDelete: (appealId: string) => `appeal/delete/${appealId}`,
  resendMessage: (messageExternalId: string) => `appeal/resend-message/${messageExternalId}`,
  cancelMessage: (messageExternalId: string) => `appeal/cancel-message/${messageExternalId}`,
  getPerson: (personId: string) => `person/${personId}`,
  getLatestConversation: (personId: string) => `person/appeals-history/${personId}`,
  updatePerson: (personId: string) => `person/${personId}/update`,
  updateWhatsappGroupChatName: (appealId: string) => `whatsapp-teletype/rename-group-chat/${appealId}`,
  updateTelegramGroupChatName: (appealId: string) => `telegram/rename-group-chat/${appealId}`,
  banPerson: (call: PersonBannedState) => `person/${call.personId}/ban`,
  unbanPerson: (call: PersonBannedState) => `person/${call.personId}/unban`,
  getPersonLog: (personId: string) => `person/${personId}/update-log`,
  setPersonNote: (personId: string) => `person/${personId}/note`,
  getPersonBoundedAppeals: (personId: string) => `person/related-appeals/${personId}`,
  removeProject: (call: RemoveByIdContext) => `project/delete/${call.id}`,
  updateProject: (call: RemoveByIdContext) => `project/update/${call.id}`,
  updatePublicApi: () => `project/update-public-api`,
  widgetConfig: (call: ChannelIdContext) => `widget/${call.channelId}/admin-config`,
  updateWidgetConfig: (call: ChannelIdContext) => `widget/${call.channelId}/update`,
  removeWidget: (call: ChannelIdContext) => `widget/${call.channelId}/delete`,
  removeWhatsappTeletype: (call: ChannelIdContext) => `whatsapp-teletype/delete/${call.channelId}`,
  getAuthLinkWhatsapp: (id: string) => `whatsapp-teletype/activate-auth-external/${id}`,
  authWhatsappExternal: (token: string) => `whatsapp-teletype/auth-external/${token}`,
  getQrForWhatsappAuth: (call: ChannelIdContext) => `whatsapp-teletype/auth/${call.channelId}`,
  createTelegram: 'telegram/create',
  getAuthLinkTelegram: (channelId: string) => `telegram/activate-auth-external/${channelId}`,
  getQrForAuthTelegram: (channelId: string) => `telegram/auth-qr-request/${channelId}`,
  sendPincodeForAuthTelegram: (channelId: string) => `telegram/auth-code-request/${channelId}`,
  finishAuthTelegram: (channelId: string) => `telegram/activate/${channelId}`,
  updateTelegram: (channelId: string) => `telegram/update/${channelId}`,
  removeTelegram: (call: ChannelIdContext) => `telegram/delete/${call.channelId}`,
  logoutTelegram: (channelId: string) => `telegram/logout/${channelId}`,
  updateWhatsappTeletype: (channelId: string) => `whatsapp-teletype/update/${channelId}`,
  setPassword: (call: SetPasswordData) => `client/set-password/${call.passwordKey}`,
  confirmPassword: 'client/confirm-password',
  passwordKey: (call: PasswordKeyData) => `client/invite/${call.projectId}`,
  clientUpdate: (id: string) => `client/update/${id}`,
  clientUpdateEmail: (hash: string) => `client/update-email/${hash}`,
  clientDelete: (id: string) => `client/delete/${id}`,
  getClientAvatar: (id: string) => `client/view-avatar/${id}`,

  listGroup: 'groups',
  createGroup: 'group/create',
  updateGroup: (id: string) => `group/update/${id}`,
  deleteGroup: (id: string) => `group/delete/${id}`,

  featuresFlags: 'project/features',
  setOnesignalSubscription: 'client/set-onesignal-web-subscription',
  // setFirebaseToken: `client/set-firebase-token`,
  notifierDelete: 'telegram-notifier/delete',
  notifierSwitch: 'telegram-notifier/checkout',
  switchPushNotification: 'project/switch-notification',
  // pushNotificationInfo: (firebaseToken: string) => `project/push-notification-info?token=${firebaseToken}`,

  switchChannelState: (call: ChannelIdContext) => `channel/checkout/${call.channelId}`,
  completeChannelCreation: (id: string) => `channel/created/${id}`,
  cancelChannelCreation: (id: string) => `channel/cancelled/${id}`,
  changeChannelSortOrder: `channel/change-sort-order`,
  appealsInfo: 'project/appeals-info',
  vkCreate: 'vk/create',
  vkUpdate: (channelId: string) => `vk/update/${channelId}`,
  vkGroups: (channelId: string) => `vk/groups/${channelId}`,
  vkDelete: (channelId: string) => `vk/delete/${channelId}`,
  vkReactivate: (channelId: string) => `vk/reactivate/${channelId}`,

  facebookCreate: 'facebook/create',
  facebookConfig: 'facebook/config',
  facebookUpdate: (channelId: string) => `facebook/update/${channelId}`,
  facebookDelete: (channelId: string) => `facebook/delete/${channelId}`,
  facebookGroups: (channelId: string) => `facebook/groups/${channelId}`,
  facebookSetup: (channelId: string) => `facebook/setup/${channelId}`,
  facebookReactivate: (channelId: string) => `facebook/reactivate/${channelId}`,

  avitoConfig: 'avito/config',
  avitoCreate: 'avito/create',
  avitoReactivate: (channelId: string) => `avito/reactivate/${channelId}`,
  avitoUpdate: (channelId: string) => `avito/update/${channelId}`,
  avitoDelete: (channelId: string) => `avito/delete/${channelId}`,

  vkDirectCreate: 'vk-direct/create',
  vkDirectReactivate: (channelId: string) => `vk-direct/auth/${channelId}`,
  vkDirectSendCode: (channelId: string) => `vk-direct/two-factor/${channelId}`,
  vkDirectUpdate: (channelId: string) => `vk-direct/update/${channelId}`,
  vkDirectDelete: (channelId: string) => `vk-direct/delete/${channelId}`,
  vkDirectLogout: (channelId: string) => `vk-direct/logout/${channelId}`,

  instagramBusinessCreate: 'instagram-comments-business/create',
  instagramBusinessConfig: 'instagram-comments-business/config',
  instagramBusinessUpdate: (channelId: string) => `instagram-comments-business/update/${channelId}`,
  instagramBusinessDelete: (channelId: string) => `instagram-comments-business/delete/${channelId}`,
  instagramBusinessGroups: (channelId: string) => `instagram-comments-business/groups/${channelId}`,
  instagramBusinessSetup: (channelId: string) => `instagram-comments-business/setup/${channelId}`,
  instagramBusinessReactivate: (channelId: string) => `instagram-comments-business/reactivate/${channelId}`,

  createNewAppeal: (channelId: string, channelType: string) => `${channelType}/create-appeal/${channelId}`,
  checkDomain: 'project/check-domain',
  crms: 'crm-channels',

  getAmoCrmRedirect: (type = 'amo-crm') => `${type}/auth-config`,
  updateAmoCRM: (channelId: string | null, type = 'amo-crm') => (channelId ? `${type}/update/${channelId}` : `${type}/create`),
  deleteAmoCRM: (channelId: string, type = 'amo-crm') => `${type}/delete/${channelId}`,
  reactivateAmoCRM: (crmId: string, type = 'amo-crm') => `${type}/reactivate/${crmId}`,
  pushAmoCRM: (appealId: string, type = 'amo-crm') => `${type}/push-appeal/${appealId}`,
  getAmoCRMPipelines: (crmId: string, type = 'amo-crm') => `${type}/pipelines/${crmId}`,
  getAmoCRMUsers: (crmId: string, type = 'amo-crm') => `${type}/users/${crmId}`,

  updateBitrixCRM: (crmId: string | null) => (crmId ? `bitrix-crm/update/${crmId}` : 'bitrix-crm/create'),
  updateBitrixCRMConnectors: (crmId: string) => `bitrix-crm/refresh-connectors/${crmId}`,
  verifyBitrixCRM: (crmId: string) => `bitrix-crm/verify/${crmId}`,
  deleteBitrixCRM: (channelId: string) => `bitrix-crm/delete/${channelId}`,
  pushBitrixCRM: (appealId: string) => `bitrix-crm/push-appeal/${appealId}`,

  sipuniCreate: 'sipuni/create',
  sipuniDelete: (integrationId: string) => `sipuni/delete/${integrationId}`,
  sipuniUpdate: (integrationId: string) => `sipuni/update/${integrationId}`,

  chatBotList: 'support-bots',
  chatBotCreate: 'support-bot/create',
  chatBotView: (id: string) => `support-bot/view/${id}`,
  chatBotUpdate: (id: string) => `support-bot/update/${id}`,
  chatBotActivate: (id: string) => `support-bot/activate/${id}`,
  chatBotDeactivate: (id: string) => `support-bot/deactivate/${id}`,
  chatBotDelete: (id: string) => `support-bot/delete/${id}`,

  getTasksList: 'background-tasks',
  createBackgroundTask: 'background-task/create',
  updateBackgroundTask: (id: string) => `background-task/update/${id}`,
  prepareTask: (id: string) => `background-task/prepare/${id}`,
  runTask: (id: string) => `background-task/run/${id}`,
  cancelTask: (id: string) => `background-task/cancel/${id}`,
  pauseTask: (id: string) => `background-task/pause/${id}`,
  deleteTask: (id: string) => `background-task/delete/${id}`,

  createYClientsIntegration: () => `y-clients/create`,
  updateYClientsIntegration: (crmExternalId: string) => `y-clients/update/${crmExternalId}`,
  deleteYClientsIntegration: (crmExternalId: string) => `y-clients/delete/${crmExternalId}`,

  createSberIntegration: () => `sber-crm/create`,
  updateSberIntegration: (crmExternalId: string) => `sber-crm/update/${crmExternalId}`,
  refreshTokenSberIntegration: (crmExternalId: string) => `sber-crm/refresh-auth-key/${crmExternalId}`,
  deleteSberIntegration: (crmExternalId: string) => `sber-crm/delete/${crmExternalId}`,

  viberUpdate: (channelId: string | null) => (channelId ? `viber/update/${channelId}` : 'viber/create'),
  viberDelete: (channelId: string) => `viber/delete/${channelId}`,
  // eslint-disable-next-line max-len
  telegramBotUpdate: (channelId: string | null) => (channelId ? `telegram-bot/update/${channelId}` : 'telegram-bot/create'),
  telegramBotDelete: (channelId: string) => `telegram-bot/delete/${channelId}`,
  telegramBotSettings: 'telegram-bot-application/view',

  templateList: 'template-messages',
  directoryList: 'template-message-directory/index',
  templateListWithDirectories: 'template-message/get-with-directories',
  createTemplate: 'template-message/create',
  editTemplate: (patternId: string) => `template-message/update/${patternId}`,
  deleteTemplate: (patternId: string) => `template-message/delete/${patternId}`,
  createTemplateDirectory: 'template-message-directory/create',
  editTemplateDirectory: (directoryId: string) => `template-message-directory/update/${directoryId}`,
  deleteTemplateDirectory: (directoryId: string) => `template-message-directory/delete/${directoryId}`,

  listCategories: 'appeal-categories/index',
  createCategory: 'appeal-categories/create',
  updateCategory: (id: string) => `appeal-categories/update/${id}`,
  deleteCategory: (id: string) => `appeal-categories/delete/${id}`,

  listTags: 'tags',
  createTag: 'tag/create',
  updateTag: (id: string) => `tag/update/${id}`,
  deleteTag: (id: string) => `tag/delete/${id}`,

  // STATISTICS ROUTES
  statistics: {
    prefix: 'statistic/get/',
    routes: {
      appealsByHour: 'appeals-by-hour',
      appealsByChannelsChart: 'appeals-by-channels-chart',
      appealsByChannelsTable: 'appeals-by-channels-table',
      appealsByDate: 'appeals-by-date',
      appealsByDayOfWeek: 'appeals-by-day-of-week',
      missedAppealsByDate: 'missed-appeals-by-date',
      operators: 'operators',
      repliedAppealsByDate: 'replied-appeals-by-date',
      widgetChannelsClicks: 'widget-channels-clicks',
      appealsAverage: 'appeals-average',
    },
  },
  referrals: 'referrals',
  sendInvitation: 'referral/send-invitation',
  paymentRequest: 'referral/payment-request',
  invitationAction: (id: string, action: string) => (action === 'reSend'
    ? `referral/resend-invitation/${id}` : `referral/delete-invitation/${id}`),
  tariffView: 'tariff/view',
  publicTariffView: 'tariff/view-price',
  // eslint-disable-next-line max-len
  tariffBilling: (page: number, itemsPerPage: number, type: string[]) => `tariff/get-billing?page=${page}&items_per_page=${itemsPerPage}&type=${type}`,
  tariffManage: 'tariff/manage',
  buyPackage: 'tariff/buy-package',
  refreshApiToken: 'project/refresh-api-token',
  createWidgetPayment: 'tariff/create-widget-payment/cloudpayments',
  getActiveRecurrentPayments: 'tariff/get-active-recurrent-payments',
  cancelPaymentSubscription: (paymentId: string) => `tariff/cancel-payment-subscription/${paymentId}`,

  tariffPriority: 'tariff/get-paid-priorities',
  updateClientsTariffPriority: 'tariff/paid-clients-priority',
  updateChannelsTariffPriority: 'tariff/paid-channels-priority',

  instagram: {
    create: 'instagram-direct/create',
    auth: '/instagram-direct/auth',
    update: (channelId: string) => `instagram-direct/update/${channelId}`,
    delete: (channelId: string) => `instagram-direct/delete/${channelId}`,
    logout: (channelId: string) => `instagram-direct/logout/${channelId}`,
    resendPassword: (channelId: string) => `instagram-direct/auth/${channelId}`,
    setCode: (channelId: string) => `instagram-direct/auth/${channelId}`,
  },

  deleteNote: (noteId: string) => `person/delete-note/${noteId}`,

  companies: {
    list: 'client-companies',
    create: 'client-company/create',
    update: (companyId: string) => `client-company/update/${companyId}`,
    delete: (companyId: string) => `client-company/delete/${companyId}`,
    invoice: (companyId: string) => `client-company/invoice/${companyId}`,
  },

  newsSeen: (newsNotificationExternalId: string) => `news/seen/${newsNotificationExternalId}`,
  privateReply: (messageId: string) => `instagram-comment/private-reply/${messageId}`,
};
