<ng-scrollbar class="scroll-container" [style.padding-bottom]="personInfoHeight" *ngIf="person">
  <app-avito-post
    *ngIf="hasPost && post"
    [post]="post"
    class="appeal-post"
  ></app-avito-post>

  <div class="person-info" *ngIf="!groupChat">
    <div class="person-details">
      <div class="person-avatar">
        <app-avatar [avatar]="person.avatar"
                    [displayStatus]="true"
                    [withPreviewer]="true"
                    [originalAvatar]="person.avatarOriginal"
                    (openPreviewer)="openPreviewer($event)"
                    [size]="60"
                    [fontSize]="24">
        </app-avatar>
        <div class="available-state" *ngIf="displayOnlineStatus" [ngClass]="{active: person.isOnline}"></div>
      </div>
      <div class="info">
        <div class="name"
             (focus)="saveValue('name')"
             (blur)="onFieldChange($event, 'name')"
             appMaxLength="255"
             contenteditable="true"
             [(ngModel)]="person.name">
        </div>
        <div *ngIf="person.location" class="location" [title]="person.location">
          {{ person.location }}
        </div>
      </div>

      <div dropdown placement="bottom right" class="dropdown person-dropdown">
        <button class="btn btn-transparent no-hover menu-icon"
                id="infoDropdown"
                (click)="handleUserBlock()"
                dropdownToggle>
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="infoDropdown" *dropdownMenu>
          <div class="dropdown-item" (click)="updateBlacklistState()">
            <i class="block-user"></i>{{ !person.banned && 'conversation.toBlackList' || 'conversation.fromBlackList' |
            translate }}
            <div class="note">
              {{ !person.banned && 'conversation.toBlackListNote' || 'conversation.fromBlackListNote' | translate }}
            </div>
          </div>
          <div class="dropdown-item" *ngIf="environment.features?.unsubscribe">
            <i class="unsubscribe-user"></i>{{ 'conversation.unsubscribe' | translate }}
            <div class="note">{{ 'conversation.unsubscribeNote' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="person-info" *ngIf="groupChat">
    <div class="person-details">
      <div class="person-avatar">
        <app-group-avatar
          [groupAvatar]="groupChat.icon"
          [size]="60"
        ></app-group-avatar>
      </div>

      <div class="info">
        <div class="name"
             (focus)="saveValue('caption')"
             (blur)="onGroupCaptionChange($event)"
             appMaxLength="255"
             contenteditable="true"
             [(ngModel)]="groupChat.caption">
        </div>
      </div>

      <!--<div dropdown placement="bottom right" class="dropdown person-dropdown">-->
      <!--  <button class="btn btn-transparent no-hover menu-icon"-->
      <!--          id="infoDropdown"-->
      <!--          (click)="handleUserBlock()"-->
      <!--          dropdownToggle>-->
      <!--  </button>-->
      <!--  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="infoDropdown" *dropdownMenu>-->
      <!--    <div class="dropdown-item" (click)="updateBlacklistState()">-->
      <!--      <i class="block-user"></i>{{ !person.banned && 'conversation.toBlackList' || 'conversation.fromBlackList' |-->
      <!--      translate }}-->
      <!--      <div class="note">-->
      <!--        {{ !person.banned && 'conversation.toBlackListNote' || 'conversation.fromBlackListNote' | translate }}-->
      <!--      </div>-->
      <!--    </div>-->
      <!--    <div class="dropdown-item" *ngIf="environment.features?.unsubscribe">-->
      <!--      <i class="unsubscribe-user"></i>{{ 'conversation.unsubscribe' | translate }}-->
      <!--      <div class="note">{{ 'conversation.unsubscribeNote' | translate }}</div>-->
      <!--    </div>-->
      <!--  </div>-->
      <!--</div>-->
    </div>
  </div>

  <accordion class="person-accordion">
    <accordion-group heading="{{ 'conversation.general' | translate }}"
                     *ngIf="!groupChat"
                     [isOpen]="stateList.general"
                     (isOpenChange)="log($event, 'general')"
                     class="general">
      <div class="info-row" *ngIf="selectedResponsibleOperator || hasAccessToSettingResponsibleOperator">
        <div>{{ 'conversation.responsibleOperator' | translate }}:</div>
        <!--сделал через hidden так как ngIf удаляет из DOM, что ломает стили-->
        <a
          class="add-operator-button"
          [hidden]="selectedResponsibleOperator"
          (click)="toggleResponsibleOperatorDropdown()"
        >
          {{ 'button.add' | translate }}
        </a>
      </div>

      <div class="control-row" *ngIf="hasAccessToSettingResponsibleOperator && isShowResponsibleOperatorDropdown && !selectedResponsibleOperator">
        <ng-select class="operator-select-wp"
                   [items]="operators"
                   bindLabel="name"
                   bindValue="id"
                   placeholder="{{ 'conversation.addPerson' | translate }}"
                   [searchFn]="searchOperator"
                   (change)="responsibleOperatorChanged(true)"
                   (search)="onTypeSearch()"
                   [clearable]="false"
                   groupBy="group"
                   [(ngModel)]="selectedResponsibleOperatorId"
                   [disabled]="setResponsibleOperatorInProgress">
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div class="avatar"
                 [style.background-image]="sanitizeUrl(item.avatar || item.avatarDefault)"></div>
            <div class="name">{{ item.name + ' ' + item.lastName | maxLength: 100 : true }}</div>
          </ng-template>
          <ng-template ng-label-tmp let-item="item">{{ 'conversation.addPerson' | translate }}</ng-template>
          <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
            <div class="ng-option disabled">
              {{ 'crm.notFound' | translate }}
            </div>
          </ng-template>
        </ng-select>
      </div>

      <div class="control-row" *ngIf="selectedResponsibleOperator">
        <div class="tiles-wrapper" >
          <div class="selected-tile">
            <div class="small-avatar"
                 [style.background-image]="sanitizeUrl(selectedResponsibleOperator.avatar || selectedResponsibleOperator.avatarDefault)">
            </div>
            <div class="name" [hidden]="selectedResponsibleOperator.lastName">
              {{ selectedResponsibleOperator.name | maxLength: 22 : true }}
            </div>
            <div class="name" [hidden]="!selectedResponsibleOperator.lastName">
              {{ selectedResponsibleOperator.name + ' ' + selectedResponsibleOperator.lastName | maxLength: 22 : true }}
            </div>
            <i class="teletype-icon-close" *ngIf="hasAccessToSettingResponsibleOperator" (click)="unSelectResponsibleOperator()"></i>
          </div>
        </div>
      </div>

      <div class="info-row" *ngIf="person?.amo">
        <div>{{ (person.amo.url.includes('kommo') ? 'conversation.kommo' : 'conversation.amoCRM') | translate }}:</div>
        <a [href]="person.amo.url" target="_blank">{{ person.amo.url | maxLength:20 :true }}</a>
      </div>

      <div class="info-row" *ngIf="person?.instagram?.username">
        <div>{{ 'conversation.nick' | translate }}:</div>
        <a [title]="person.instagram.username" href="{{person.instagram.url}}" target="_blank">
          <ng-container *ngIf="person.instagram.username.length > 18; else instagramPersonFullUsername">
            {{ person.instagram.username | slice:0:15 }}...
          </ng-container>

          <ng-template #instagramPersonFullUsername>
            {{ person.instagram.username }}
          </ng-template>
        </a>
      </div>

      <div class="info-row" *ngIf="personCurrentTime">
        <div>{{ 'conversation.localTime' | translate }}:</div>
        <div>{{ personCurrentTime || 'conversation.unknown' | translate }}</div>
      </div>
      <div class="info-row" *ngIf="person.devicePlatform">
        <div>{{ 'conversation.platform' | translate }}:</div>
        <div>{{ person.devicePlatform || 'conversation.unknown' | translate }}</div>
      </div>
      <div class="info-row" *ngIf="person.userAgent">
        <div>{{ 'conversation.browser' | translate }}:</div>
        <div>{{ person.userAgent || 'conversation.unknown' | translate }}</div>
      </div>
      <div class="info-row" *ngIf="personTelegramNickname">
        <div>{{ 'conversation.nickname' | translate }}:</div>
        <a class="nickname" [href]="'https://t.me/' + personTelegramNickname" target="_blank">
          {{ personTelegramNickname || 'conversation.unknown' | translate }}
        </a>
      </div>
      <div class="info-row">
        <div>{{ 'conversation.phone' | translate }}:</div>
        <div class="phone blocked" *ngIf="person.phoneIsLocked">
          {{ person.phone || 'conversation.unknown' | translate }}
        </div>
        <div class="phone"
             *ngIf="!person.phoneIsLocked"
             (focus)="saveValue('phone')"
             (blur)="onFieldChange($event, 'phone')"
             (keydown.enter)="onEnter($event)"
             appMaxLength="18"
             contenteditable="true"
             [attr.placeholder]="'conversation.add' | translate"
             [(ngModel)]="person.phone">
        </div>
        <button
          *ngIf="person.phone && (sipuniService.isAvailableCalls$ | async)"
          (click)="sipuniService.makeCall(person.phone)"
          class="ip-call"
        ></button>
      </div>
      <div class="info-row">
        <div>{{ 'conversation.personEmail' | translate }}:</div>
        <div class="phone blocked" *ngIf="person.emailIsLocked">
          {{ person.email || 'conversation.unknown' | translate }}
        </div>
        <div class="custom-tooltip">
          <span class="tooltip-text tooltip-text-phone" *ngIf="person?.email?.length > 23">{{ person?.email }}</span>
          <div class="phone"
               id="email"
               *ngIf="!person.emailIsLocked"
               (focus)="saveValue('email')"
               (blur)="onFieldChange($event, 'email')"
               (keydown.enter)="onEnter($event)"
               appMaxLength="38"
               contenteditable="true"
               [attr.placeholder]="'conversation.add' | translate"
               [(ngModel)]="person.email">
          </div>
        </div>

      </div>
      <div class="info-row" *ngIf="person.vk">
        <div>{{ 'conversation.vk' | translate }}:</div>
        <div>
          <a [href]="person.vk.url" target="_blank">{{ vkUrlLabel }}</a>
        </div>
      </div>

      <div class="info-toggle" (click)="toggleAddInfo()">
        {{ personInfoIsVisible && '-' || '+' }}&nbsp;{{ 'show' | translate }}
        {{ !personInfoIsVisible && 'show.additional' || '' | translate }}
        {{ !personInfoIsVisible && additionalFieldsCount || ''}}
        {{ !personInfoIsVisible && 'more' || 'less' | translate }}
      </div>

      <div *ngIf="personInfoIsVisible">
        <div class="info-row" *ngIf="person?.averageRate?.rate">
          <div>{{ 'conversation.rating' | translate }}:</div>
          <div>{{ person?.averageRate?.rate || ('conversation.unknown' | translate) }}</div>
        </div>
        <div class="info-row" *ngIf="person.firstMessageAt">
          <div>{{ 'conversation.firstSeen' | translate }}:</div>
          <div class="custom-tooltip" *ngIf="person.firstMessageAt">
            <span class="tooltip-text">{{ person?.humanDates?.firstMessageAt }}</span>
            <div>{{getAgo(person.firstMessageAt)}}</div>
          </div>
          <div *ngIf="!person.firstMessageAt">{{ 'conversation.unknown' | translate }}</div>
        </div>
        <div class="info-row" *ngIf="person.lastOnlineAt">
          <div>{{ 'conversation.lastSeen' | translate }}:</div>
          <div class="custom-tooltip" *ngIf="person.lastOnlineAt">
            <span class="tooltip-text">{{ person?.humanDates?.lastOnlineAt }}</span>
            <div>{{getAgo(person.lastOnlineAt)}}</div>
          </div>
          <div *ngIf="!person.lastOnlineAt">{{ 'conversation.unknown' | translate }}</div>
        </div>
        <div class="info-row" *ngIf="person.lastMessageAt">
          <div>{{ 'conversation.lastContacted' | translate }}:</div>
          <div class="custom-tooltip" *ngIf="person.lastMessageAt">
            <span class="tooltip-text">{{ person?.humanDates?.lastMessageAt }}</span>
            <div>{{getAgo(person.lastMessageAt)}}</div>
          </div>
          <div *ngIf="!person.lastMessageAt">{{ 'conversation.unknown' | translate }}</div>
        </div>
        <div class="info-row" *ngIf="person?.info?.utmCampaign">
          <div>{{ 'conversation.utmCampaign' | translate }}:</div>
          <div>{{ person?.info?.utmCampaign || 'conversation.unknown' | translate }}</div>
        </div>
        <div class="info-row" *ngIf="person.language">
          <div>{{ 'conversation.language' | translate }}:</div>
          <div>{{ person.language || 'conversation.unknown' | translate }}</div>
        </div>
        <div class="info-row" *ngIf="person?.ip">
          <div>IP:</div>
          <div>{{ person?.ip || 'conversation.unknown' | translate }}</div>
        </div>
      </div>
    </accordion-group>

    <accordion-group heading="{{ 'conversation.customFields' | translate }}"
                     *ngIf="isShowCustomFields"
                     [isOpen]="stateList.customFields"
                     (isOpenChange)="log($event, 'customFields')">
      <app-person-custom-fields
        *ngIf="isCustomFieldsLoaded"
        [personId]="person.id"
      ></app-person-custom-fields>
    </accordion-group>

    <accordion-group heading="{{ 'conversation.lastViewed' | translate }}"
                     [isOpen]="stateList.lastViewed"
                     (isOpenChange)="log($event, 'lastViewed')"
                     class="last-viewed"
                     *ngIf="lastWebSessions?.length > 0">
      <div class="last-web-sessions">
        <div *ngFor="let item of lastWebSessions; let i = index;">
          <div class="text" *ngIf="i < 3">
            <div class="custom-tooltip custom-tooltip-last-viewed">
              <div class="tooltip-text tooltip-last-viewed">
                <div class="link-title"><b>{{ item.title }}</b></div>
                <a target="_blank" [href]="item.url | unescape">{{ item.url | unescape }}</a>
              </div>
              <span class="history-title">{{ item.title | maxLength : 20 : true }}</span>
            </div>
            <div class="custom-tooltip">
              <span class="tooltip-text">{{ item?.humanDate }}</span>
              <app-message-date *ngIf="item.date"
                                class="message-date"
                                [date]="item.date"
                                [status]="undefined"
                                [seen]="false"
                                [attr.date-anchor]="item.date"
                                [timeOnly]="false">
              </app-message-date>
            </div>
          </div>
        </div>

        <div class="info-toggle"
             (click)="lastViewIsVisible = !lastViewIsVisible"
             *ngIf="lastWebSessionsCount - 3 > 0">
          {{ lastViewIsVisible && '-' || '+' }}&nbsp;
          {{ !lastViewIsVisible && 'conversation.showMoreBefore' || '' | translate }}
          {{ !lastViewIsVisible && lastWebSessionsCount - 3 || ''}}
          {{ !lastViewIsVisible && 'conversation.showMoreAfter' || 'conversation.hide' | translate }}
          {{ !lastViewIsVisible && lastWebSessionsCount - 3 && '...' || '' }}
        </div>

        <div *ngIf="lastViewIsVisible">
          <div *ngFor="let item of lastWebSessions; let i = index;">
            <div class="text" *ngIf="i >= 3">
              <div class="custom-tooltip custom-tooltip-last-viewed">
                <div class="tooltip-text tooltip-last-viewed">
                  <a target="_blank" [href]="item.url"><b>{{ item.title }}</b></a>
                  <a target="_blank" [href]="item.url">{{ item.url }}</a>
                </div>
                <span class="history-title">{{ item.title | maxLength : 20 : true }}</span>
              </div>
              <div class="custom-tooltip">
                <span class="tooltip-text">{{ item?.humanDate }}</span>
                <app-message-date *ngIf="item.date"
                                  class="message-date"
                                  [date]="item.date"
                                  [status]="undefined"
                                  [seen]="false"
                                  [attr.date-anchor]="item.date"
                                  [timeOnly]="false"
                                  [nowText]="nowText">
                </app-message-date>
              </div>
            </div>
          </div>
        </div>
      </div>
    </accordion-group>

    <accordion-group heading="{{ 'conversation.utm' | translate }}"
                     [isOpen]="stateList.utm"
                     (isOpenChange)="log($event, 'utm')"
                     class="general"
                     *ngIf="person?.info?.utmCampaign || person?.info?.utmContent || person?.info?.utmMedium ||
                        person?.info?.utmSource || person?.info?.utmTerm">
      <div class="info-row" *ngIf="person.info.utmCampaign">
        <div>{{ 'conversation.utmCampaign' | translate }}:</div>
        <div class="utm-value custom-tooltip">
          <div class="tooltip-text tooltip-last-viewed">
            <div>{{ person.info.utmCampaign }}</div>
          </div>
          <span class="ellipsis">
            {{ person.info.utmCampaign }}
          </span>
        </div>
      </div>
      <div class="info-row" *ngIf="person.info.utmContent">
        <div>{{ 'conversation.utmContent' | translate }}:</div>
        <div class="custom-tooltip">
          <div class="tooltip-text tooltip-last-viewed">
            <div>{{ person.info.utmContent }}</div>
          </div>
          <span class="ellipsis">
            {{ person.info.utmContent }}
          </span>
        </div>
      </div>
      <div class="info-row" *ngIf="person.info.utmMedium">
        <div>{{ 'conversation.utmMedium' | translate }}:</div>
        <div class="utm-value custom-tooltip">
          <div class="tooltip-text tooltip-last-viewed">
            <div>{{ person.info.utmMedium }}</div>
          </div>
          <span class="ellipsis">
            {{ person.info.utmMedium }}
          </span>
        </div>
      </div>
      <div class="info-row" *ngIf="person.info.utmSource">
        <div>{{ 'conversation.utmSource' | translate }}:</div>
        <div class="utm-value custom-tooltip">
          <div class="tooltip-text tooltip-last-viewed">
            <div>{{ person.info.utmSource }}</div>
          </div>
          <span class="ellipsis">
            {{ person.info.utmSource }}
          </span>
        </div>
      </div>
      <div class="info-row" *ngIf="person.info.utmTerm">
        <div>{{ 'conversation.utmTerm' | translate }}:</div>
        <div class="utm-value custom-tooltip">
          <div class="tooltip-text tooltip-last-viewed">
            <div>{{ person.info.utmTerm }}</div>
          </div>
          <span class="ellipsis">
            {{ person.info.utmTerm }}
          </span>
        </div>
      </div>
    </accordion-group>

    <!-- Not implemented yet -->
    <!--<accordion-group heading="{{ 'conversation.referralUrl' | translate }}"></accordion-group>-->

    <accordion-group heading="{{ 'conversation.assignTo' | translate }}"
                     *ngIf="operators && operators.length > 0"
                     [isOpen]="stateList.assignTo"
                     (isOpenChange)="log($event, 'assignTo')">
      <div class="control-row">
        <ng-select class="operator-select-wp"
                   [items]="operators"
                   bindLabel="name"
                   bindValue="id"
                   placeholder="{{ 'conversation.addPerson' | translate }}"
                   [searchFn]="searchOperator"
                   (change)="operatorChanged(true)"
                   (search)="onTypeSearch()"
                   [clearable]="false"
                   groupBy="group"
                   [(ngModel)]="selectedOperatorId"
                   [disabled]="setOperatorInProgress || !isOpen">
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div class="avatar"
                 [style.background-image]="sanitizeUrl(item.avatar || item.avatarDefault)"></div>
            <div class="name">{{ item.name + ' ' + item.lastName | maxLength: 100 : true }}</div>
          </ng-template>
          <ng-template ng-label-tmp let-item="item">{{ 'conversation.addPerson' | translate }}</ng-template>
          <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
            <div class="ng-option disabled">
              {{ 'crm.notFound' | translate }}
            </div>
          </ng-template>
        </ng-select>

        <div class="tiles-wrapper" *ngIf="selectedOperator">
          <div class="selected-tile">
            <div class="small-avatar"
                 [style.background-image]="sanitizeUrl(selectedOperator.avatar || selectedOperator.avatarDefault)">
            </div>
            <div class="name" [hidden]="selectedOperator.lastName">
              {{ selectedOperator.name | maxLength: 22 : true }}
            </div>
            <div class="name" [hidden]="!selectedOperator.lastName">
              {{ selectedOperator.name + ' ' + selectedOperator.lastName | maxLength: 22 : true }}
            </div>
            <i class="teletype-icon-close" (click)="unSelectOperator()" *ngIf="isOpen"></i>
          </div>
        </div>
      </div>
    </accordion-group>

    <accordion-group heading="{{ 'conversation.assignedTags' | translate }}"
                     *ngIf="tags && tags.length > 0 && (isTagsAvailable | async)"
                     [isOpen]="stateList.assignedTags"
                     (isOpenChange)="log($event, 'assignedTags')">
      <div class="control-row">
        <ng-select class="operator-select-wp _tags"
                   [items]="tags"
                   bindLabel="tag"
                   bindValue="id"
                   [placeholder]="'conversation.addTag' | translate"
                   (add)="tagChanged($event, true)"
                   (remove)="deleteTag($event)"
                   [clearable]="false"
                   [(ngModel)]="selectedTagsId"
                   [multiple]="true"
                   [maxSelectedItems]="5"
                   [disabled]="setTagInProgress || (selectedTagsId.length > 4)">
          <ng-template ng-label-tmp let-item="item">{{ 'conversation.addTag' | translate }}</ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div class="name">
              <div class="tag-icon" [style.background-color]="item.color"></div>
              {{ item.tag }}
            </div>
          </ng-template>
          <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
            <div class="ng-option disabled">
              {{ 'crm.notFound' | translate }}
            </div>
          </ng-template>
        </ng-select>

        <div class="tiles-wrapper" *ngIf="selectedTags">
          <div class="selected-tile tag" *ngFor="let selectedTag of selectedTags">
            <div class="name">
              <div class="tag-icon" [style.background-color]="selectedTag.color"></div>
              {{ selectedTag.tag }}
            </div>
            <i class="teletype-icon-close" (click)="deleteTag(selectedTag.id)"></i>
          </div>
        </div>
      </div>
    </accordion-group>

    <accordion-group heading="{{ 'conversation.setCategories' | translate }}"
                     *ngIf="isCategoriesInTariff && categories && categories.length > 0"
                     [isOpen]="stateList.categories"
                     (isOpenChange)="log($event, 'categories')">
      <div class="control-row">
        <ng-select class="operator-select-wp"
                   [items]="categories"
                   bindLabel="category"
                   bindValue="id"
                   placeholder="{{ 'conversation.addCategory' | translate }}"
                   (change)="categoryChanged(true)"
                   [clearable]="false"
                   [(ngModel)]="selectedCategoryId"
                   [disabled]="setCategoryInProgress || !isOpen">
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div class="name">{{ item.category | maxLength: 100 : true }}</div>
          </ng-template>
          <ng-template ng-label-tmp let-item="item">{{ 'conversation.addCategory' | translate }}</ng-template>
          <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
            <div class="ng-option disabled">
              {{ 'crm.notFound' | translate }}
            </div>
          </ng-template>
        </ng-select>

        <div class="tiles-wrapper" *ngIf="selectedCategory">
          <div class="selected-tile">
            <div class="name">
              {{ selectedCategory.category | maxLength: 22 : true }}
            </div>
            <i class="teletype-icon-close" (click)="unSelectCategory()" *ngIf="isOpen"></i>
          </div>
        </div>
      </div>
    </accordion-group>

    <ng-container
      *ngTemplateOutlet="
        editNotes;
        context: {
          notes: groupChatPersonId ? groupChatPersonNotes : person.notes,
          personId: groupChatPersonId || person.id
        }"
    ></ng-container>

    <accordion-group [isOpen]="stateList.latestConversations"
                     (isOpenChange)="log($event, 'latestConversations')"
                     *ngIf="isShowLatestConversation">
      <div accordion-heading>
        <div class="accordion-heading">
          <button class="btn btn-link btn-block clearfix">
            <div>{{ 'conversation.latest' | translate }}</div>
            <span class="title-counter" *ngIf="isShowLatestConversation">
              {{ ' (' + countLatestConversation + ')' }}
            </span>
          </button>
        </div>
      </div>
      <div class="appeal">
        <app-appeal-item *ngFor="let appeal of person.appealsHistory; index as i; let last = last; trackBy: appealsTrack"
                         [class]="{'with-label': true, 'person': true, 'first': i === 0, 'last': last}"
                         [hasLabel]="true"
                         [appeal]="appeal"
                         [isPerson]="true"
                         [avatarSize]="26"
                         [avatarFontSize]="11"
                         [showTyping]="false"
                         (click)="showConversation(appeal)">
        </app-appeal-item>

        <app-appeal-item *ngFor="let appeal of boundedAppeals; index as i; let last = last; trackBy: appealsTrack"
                         [class]="{'with-label': true, 'person': true, 'first': i === 0, 'last': last}"
                         [hasLabel]="true"
                         [appeal]="appeal"
                         [isPerson]="true"
                         [avatarSize]="26"
                         [avatarFontSize]="11"
                         [showTyping]="false"
                         (click)="showConversation(appeal)">
        </app-appeal-item>
      </div>
    </accordion-group>

    <accordion-group heading="AmoCRM" [isOpen]="stateList.crms" (isOpenChange)="log($event, 'crms')" *ngIf="hasAmoCRM">
      <button class="btn amo-crm-upload" (click)="uploadDataToAmaCRM()" [disabled]="dataUploadInProgress">
        <span>
          {{ 'conversation.exportToCRM' | translate }}
        </span>
      </button>
    </accordion-group>
    <accordion-group [isOpen]="stateList.bitrix24" (isOpenChange)="log($event, 'bitrix24')" *ngIf="hasBitrix24">
      <div accordion-heading>
        <div class="accordion-heading">
          <button class="btn btn-link btn-block clearfix">
            <div>{{ 'crm.bitrix_crm' | translate }}</div>
          </button>
        </div>
      </div>
      <button class="btn amo-crm-upload" (click)="uploadDataToBitrix24()" [disabled]="dataUploadBitrixInProgress">
        <span>
          {{ 'conversation.exportToCRM' | translate }}
        </span>
      </button>
    </accordion-group>
    <accordion-group
      [isOpen]="stateList.history"
      (isOpenChange)="log($event, 'history')"
    >
      <!--*ngIf="logs?.length > 0"-->
      <div accordion-heading>
        <div class="accordion-heading">
          <button class="btn btn-link btn-block clearfix">
            <div>{{ 'conversation.showHistory' | translate  }}</div>
            <span class="title-counter" *ngIf="logs?.length">{{ ' (' + logs?.length + ')' }}</span>
          </button>
        </div>
      </div>

      <div class="no-data" *ngIf="logs && logs.length === 0">
        {{ 'conversation.foundNothing' | translate }}
      </div>

      <div class="log-wrapper" *ngFor="let log of logs">
        <div class="row-container">
          <div class="operator-avatar">
            <app-avatar [avatar]="log.avatar || log.avatarDefault" [size]="32" [fontSize]="16">
            </app-avatar>
          </div>

          <div class="operator-name">
            <span>{{ log.operatorName }}</span>
            <span>{{ 'conversation.who' | translate}}
              {{ (log.name === 'name' && 'conversation.changedField.name' | translate) ||
              (log.name === 'phone' && 'conversation.changedField.phone' | translate) ||
              'conversation.changedField.email' | translate}}
            </span>
          </div>
        </div>
        <div class="operator-name values">
          <span>{{ 'conversation.from' | translate }}: </span><span>{{ log.oldValue }}</span>
        </div>
        <div class="operator-name values">
          <span>{{ 'conversation.to' | translate }}: </span><span>{{ log.newValue }}</span>
        </div>
        <div class="custom-tooltip custom-tooltip-log">
          <span class="tooltip-text">{{ log.humanDate }}</span>
          <app-message-date [date]="log.createdAt" [logFormat]="true"></app-message-date>
        </div>
      </div>
    </accordion-group>

    <ng-template #editNotes let-notes="notes" let-personId="personId">
      <accordion-group [isOpen]="stateList.notes" (isOpenChange)="log($event, 'notes')">
        <div accordion-heading>
          <div class="accordion-heading">
            <button class="btn btn-link btn-block clearfix">
              <div>{{ 'conversation.notesTitle' | translate }}</div>
              <span class="title-counter" *ngIf="notes?.length">{{ ' (' +notes?.length + ')' }}</span>
            </button>
            <div  class="action" (click)="addNote($event)">{{ 'conversation.newNotes' | translate }}</div>
          </div>
        </div>

        <div class="note-section">
          <div class="new-note" *ngIf="isEdit">
          <textarea [disabled]="notesLoading" class="note-input"
                    (input)="noteText = $event.target.value"
                    placeholder="{{ 'conversation.typeNote' | translate }}"></textarea>

            <div class="control-row">
              <button type="button" [disabled]="notesLoading" class="save-btn btn" (click)="saveNote(personId)">{{ 'button.create' | translate }}</button>
              <button class="cancel-btn" (click)="cancel()">{{ 'button.cancel' | translate }}</button>
            </div>
          </div>

          <div class="notes-wrapper" *ngFor="let note of notes; let last = last;" [ngClass]="{last: last}">
            <div class="row-line">
              <div class="operator-avatar" *ngIf="note.operator">
                <app-avatar [avatar]="note.operator.avatar || note.operator.avatarDefault" [size]="32" [fontSize]="16">
                </app-avatar>
              </div>
              <div class="operator-name" *ngIf="note.operator">{{ note.operator.name }}</div>
              <div class="note-date">
                <app-message-date [date]="note.createdAt"></app-message-date>
              </div>
              <div class="note-remove" (click)="removeNote(note)"></div>
            </div>
            <div class="note-content">{{ note.text }}</div>
          </div>
        </div>
      </accordion-group>
    </ng-template>
  </accordion>

  <app-image-previewer (hide)="hideImagePreviewer()" *ngIf="showPreviewer"
                       [image]="previewerImage"></app-image-previewer>

</ng-scrollbar>
