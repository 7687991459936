import { Injectable } from '@angular/core';
import {ChannelData, OperatorData, Response} from '@app/core/interfaces/api.response';
import { PermissionsService } from '@app/core/permissions.service';
import { YaglaAnalyticsService } from '@app/core/yagla.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { each, find, findIndex } from 'lodash-es';
import { WebsocketsService, WsEvent } from '@app/core/websockets.service';
import { UtilsService } from '@app/core/utils.service';
import { ChannelApiService } from '@app/core/api/api.channel';
import { I18nService } from '@app/core/i18n.service';
import { UnescapePipe } from '@app/shared/unescape/unescape.pipe';
import { ApiService } from '@app/core/api/api.service';
import { TranslateService } from '@ngx-translate/core';
import { ProjectService } from '@app/core/project.service';
import { ANALYTICS_EVENT, MetrikaService } from '@app/core/metrika.service';
import { FaceBookPixelService } from '@app/core/fbpixel.service';
import { GoogleTagService } from '@app/core/gtag.service';
import { UserService } from '@app/core/user.service';
import { VkAnalyticsService } from '@app/core/vk.service';
import { MailRuAnalyticsService } from '@app/core/mail-ru.service';
import {finalize, takeUntil} from 'rxjs/operators';
import {StorageService} from '@app/core/storage/storage.service';
import { fa } from 'timeago.js/lib/lang';

export enum CHANNEL_CREATION_STEP {
  PREPARED = 10,
  INITIATED = 20,
  CANCELED = 30,
  CREATED = 40,
}

export enum ChannelType {
  InstagramDirect = 'instagram_direct',
  InstagramDirectBusiness = 'instagram_direct_business',
  InstagramComments = 'instagram_comment',
  InstagramCommentsBusiness = 'instagram_comments_business',
  WhatsappTeletype = 'whatsapp_teletype',
  WhatsappEdna = 'whatsapp_edna',
  TelegramBot = 'telegram-bot',
  Telegram = 'telegram',
  Widget = 'widget',
  /* псевдоним, такого type у channel нет, это type=widget у которого enabledChat=true */
  OnlineChat = 'online-chat',
  /* псевдоним, такого type у channel нет, это type=widget у которого enabledChat=false */
  MessengerButton = 'messenger-button',
  VkGroup = 'vk',
  VkDirect = 'vk_direct',
  Avito = 'avito',
  Viber = 'viber',
  Mail = 'mail',
  Facebook = 'facebook'
}

@Injectable({
  providedIn: 'root',
})
export class ChannelService {
  private _counters: any;

  public channels$: BehaviorSubject<ChannelData[]> = new BehaviorSubject(null);
  public allChannels$: BehaviorSubject<ChannelData[]> = new BehaviorSubject(null);
  public isChannelsLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private channelDeleted = new BehaviorSubject(null);

  private appealCounters = new BehaviorSubject<any>(undefined);
  private readonly translations: any;
  private requestPending: boolean;
  public channelsLoaded: boolean;

  get isChannelsLoaded$() {
    return this.isChannelsLoaded.asObservable();
  }

  get channels(): ChannelData[] {
    return this.channels$.getValue();
  }

  set channels(channels: ChannelData[]) {
    this.channels$.next(channels);
  }

  get allChannels(): ChannelData[] {
    return this.allChannels$.getValue();
  }

  set allChannels(channels: ChannelData[]) {
    this.allChannels$.next(channels);
  }

  set counters(counters: any) {
    this._counters = counters;
    this.appealCounters.next(this._counters);
  }

  constructor(
    private api: ChannelApiService,
    private ws: WebsocketsService,
    private utils: UtilsService,
    private i18nService: I18nService,
    private mainApi: ApiService,
    private translateService: TranslateService,
    private projectService: ProjectService,
    private metrika: MetrikaService,
    private fbPixel: FaceBookPixelService,
    private vk: VkAnalyticsService,
    private yagla: YaglaAnalyticsService,
    private mailRu: MailRuAnalyticsService,
    private gtag: GoogleTagService,
    private userService: UserService,
    private permissionServices: PermissionsService,
    private storage: StorageService,
  ) {
    this.translations = this.i18nService.translateService.translations[this.i18nService.language];

    this.ws.on(WsEvent.CHANNEL_STATUS_UPDATE, (_data: any) => {
      const data = this.utils.camelKeys(_data);
      if (data.projectId !== this.projectService.currentProject.id && data?.channelId) {
        return;
      }
      this.handleUpdateChannels(data);
    });

    this.ws.on(WsEvent.CHANNEL_UPDATED, (_data: any) => {
      const data = this.utils.camelKeys(_data);
      if (data.projectId !== this.projectService.currentProject.id && data?.channelId) {
        return;
      }
      this.handleUpdateChannels(data);
    });

    this.ws.on(WsEvent.CHANNELS_ORDER_UPDATED, (_data: any) => {
      const data = this.utils.camelKeys(_data);
      if (data.projectId !== this.projectService.currentProject.id && data?.channelId) {
        return;
      }
      this.updateChannelsList();
    });

    this.ws.on(WsEvent.CHANNEL_DELETED, (_data: any) => {
      const data = this.utils.camelKeys(_data);
      if (data.projectId !== this.projectService.currentProject.id) {
        return;
      }
      this.allChannels = this.allChannels.filter(c => c.id !== data.channelId);
      this.channels =  this.allChannels.filter((c) => c.creationStep === CHANNEL_CREATION_STEP.CREATED);

      this.channelDeleted.next(data);
    });

    this.projectService.isProjectsLoaded$.subscribe(isLoading => {
      if (isLoading) {
        this.updateChannelsList();
      }
    });
  }

  getChannels(): Observable<any> {
    return this.channels$.asObservable();
  }

  getAllChannels(): Observable<any> {
    return this.allChannels$.asObservable();
  }

  onChannelsComplete(response: Response) {
    if (response && response.success && response.data?.length) {
      const channels = response.data as any[];
      each(channels, (channel) => {
        const type = channel.type
          .replace('widget', channel.enabledChat ? 'Online chat' : 'Website button')
          .replace('mail', 'Email')
          .replace('whatsapp', 'WhatsApp')
          .replace('vkontakte', 'VKontakte')
          .replace('instagram_comment', 'Instagram Comments')
          .replace('instagram_direct', 'Instagram Direct')
          .replace('instagram_direct_business', 'Instagram Direct Business');
        channel.defaultName = type.charAt(0).toUpperCase() + type.slice(1);
        channel.defaultName = channel.defaultName.replace('Vk', 'VK');
        channel.defaultName = this.translations && this.translations.channelNames[channel.defaultName]
          || channel.defaultName;
        channel.name = new UnescapePipe().transform(channel.name);

        if (channel.type === 'whatsapp_teletype' && !channel.isPaid && channel.name.indexOf(' (Free)') === -1) {
          channel.name += ' (Free)';
        }

        channel.statusTooltip = this.getStatusTooltipText(channel);
      });
      this.channels = channels.filter((c) => c.creationStep === CHANNEL_CREATION_STEP.CREATED);
      this.allChannels = channels;
    }
  }

  getStatusTooltipText(channel: any) {
    const tooltips = {
      widget: {
        pending: this.translateService.instant('channelTooltips.widget.pending'),
      },
      messengerButton: {
        pending: this.translateService.instant('channelTooltips.messengerButton.pending'),
      },
    };

    const channelType = this.transformChannelType(channel);

    return tooltips[channelType] ? (tooltips[channelType][channel.state] || null) : null;
  }

  transformChannelType(channel: any): string {
    if (channel.type === 'widget' && channel.enabledChat === false) {
      return 'messengerButton';
    }

    return channel.type;
  }

  handleUpdateChannels(wsData: ChannelData = null) {
    if (wsData?.channelId) {
      const { channelId } = wsData;
      const channels = this.allChannels?.length > 0 ? this.allChannels : [];
      const matchChannel = find(channels, (channel) => channel.id === channelId);

      if (matchChannel && wsData.state) {
        matchChannel.state = wsData.state;
        if (matchChannel.creationStep === CHANNEL_CREATION_STEP.CREATED && this.permissionServices.isAdmin()) {
          this.metrika.goal(ANALYTICS_EVENT.CHANNEL_CONNECT);
          this.gtag.track(ANALYTICS_EVENT.CHANNEL_CONNECT);
          this.fbPixel.track(ANALYTICS_EVENT.CHANNEL_CONNECT);
          this.vk.track(ANALYTICS_EVENT.CHANNEL_CONNECT);
          this.yagla.track(ANALYTICS_EVENT.CHANNEL_CONNECT);
          this.mailRu.track(ANALYTICS_EVENT.CHANNEL_CONNECT);
        }
        Object.assign(matchChannel, matchChannel, wsData);
        this.onChannelsComplete({data: channels, success: true, errors: []});
      } else {
        this.updateChannelsList();
      }
    }
  }

  updateChannelsList() {
    if (this.requestPending) {
      return;
    }
    this.requestPending = true;

    if (!this.storage.currentProjectId) {
      this.projectService.isProjectsLoaded$.pipe().subscribe(isLoaded => {
        if (isLoaded) {
          this.channelsLoad();
        }
      });
    } else {
      this.channelsLoad();
    }
  }

  private channelsLoad(): void {
    this.api.getChannelsList().pipe(
      finalize(() => {
        this.isChannelsLoaded.next(true);
        this.requestPending = false;
      })
    ).subscribe((response: Response) => {
      this.onChannelsComplete(response);
      this.channelsLoaded = true;
    });
  }

  getChannelTypeById(id: string) {
    const match = (this.channels || []).find((channel) => channel.id === id);
    if (match) {
      return match.type;
    }
  }

  updateAppealsCounter() {
    setTimeout(() => {
      this.mainApi.getAppealsInfo().subscribe((response: any) => {
        if (response.success && response.data) {
          this.counters = response.data;
        }
      });
    }, 100);
  }

  getAppealsInfo() {
    return this.appealCounters.asObservable();
  }

  onChannelCounterChanged(channelId: string, count: number) {
    const matchChannel = find(this.channels, (_channel) => _channel.id === channelId);
    if (matchChannel) {
      if (!this.isLocked(matchChannel.appealsCount)) {
        const sum = matchChannel.appealsCount + count;
        matchChannel.appealsCount = sum <= 0 ? 0 : sum;
      }
      this.channels$.next(this.channels);
    }
  }

  setUnansweredCount(unanswered: number) {
    if (!this._counters) {
      return;
    }
    this._counters.unanswered = unanswered;
    this.appealCounters.next(this._counters);
  }

  /** это функция, которая проверяет является счетчик изменяемым */
  isLocked(variable: unknown) {
    if (typeof variable === 'string') {
      return true;
    }
  }

  onCounterChanged(open: string | null, unanswered: string | null, assigned: string | null) {
    if (!this._counters) {
      return;
    }

    if (unanswered && !this.isLocked(unanswered)) {
      this._counters.unread = unanswered === '+' ? this._counters.unanswered + 1 : this._counters.unanswered - 1;
    }

    if (open && !this.isLocked(unanswered)) {
      this._counters.open = open === '+' ? this._counters.open + 1 : this._counters.open - 1;
    }

    if (assigned && !this.isLocked(unanswered)) {
      this._counters.assigned = assigned === '+' ? this._counters.assigned + 1 : this._counters.assigned - 1;
    }

    this.appealCounters.next(this._counters);
  }

  completeChannelCreation(id: string) {
    return this.api.completeChannelCreation(id);
  }

  cancelChannelCreation(id: string) {
    return this.api.cancelChannelCreation(id);
  }

  public onChannelDeleted(): Observable<any> {
    return this.channelDeleted.asObservable();
  }

  public getChannelById(channelId: string): ChannelData {
    const match = this.allChannels.find(channel => channel.id === channelId);
    if (match) {
      return match
    } else {
      throw new Error(`Channel with id ${channelId} doesn't exist`);
    }
  }

  public hasChannelWithType(channelType: ChannelType): boolean {
    if (!this.channels) {
      return false;
    }

    const channel = this.channels.find(channel => channel.type === channelType);
    return Boolean(channel);
  }

  public getChannelTypeName(channelType: ChannelType) {
    const translationKeyMap = {
      [ChannelType.InstagramDirect]: 'instagramDirect',
      [ChannelType.InstagramDirectBusiness]: 'instagramDirectBusiness',
      [ChannelType.InstagramComments]: 'instagramComments',
      [ChannelType.InstagramCommentsBusiness]: 'instagramCommentsBusiness',
      [ChannelType.WhatsappTeletype]: 'whatsapp',
      [ChannelType.TelegramBot]: 'telegrambot',
      [ChannelType.Telegram]: 'telegram',
      [ChannelType.Widget]: 'chat',
      [ChannelType.OnlineChat]: 'chat',
      [ChannelType.MessengerButton]: 'addMessengers',
      [ChannelType.VkGroup]: 'vk',
      [ChannelType.VkDirect]: 'vk_direct',
      [ChannelType.Avito]: 'avito',
      [ChannelType.Viber]: 'viber',
      [ChannelType.Mail]: 'mail',
      [ChannelType.Facebook]: 'facebook',
    };

    return this.translations.settings.channelTiles[translationKeyMap[channelType]];
  }
}
