import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { I18nService } from '@app/core';
import { AlertsService } from '@app/core/alerts/alerts.service';
import { ApiBitrixService } from '@app/core/api/api.bitrix';
import { AppealsListResponseDataItem } from '@app/core/api/api.conversation';
import { NotesContext, PersonApiService, PersonSetResponsibleContext } from '@app/core/api/api.person';
import { CategoriesService, Category } from '@app/core/categories.service';
import { EMAIL_REGEX, PHONE_REGEX } from '@app/core/config/regExValidators';
import { FeatureAccessService } from '@app/core/feature-access.service';
import { FeatureFlagsService } from '@app/core/feature-flags.service';
import { AvitoPost, GroupChatInfo, OperatorData, Response } from '@app/core/interfaces/api.response';
import { Person } from '@app/core/interfaces/message';
import { OperatorService } from '@app/core/operator.service';
import { Permission, PermissionsService } from '@app/core/permissions.service';
import { ProjectService } from '@app/core/project.service';
import { SipuniService } from '@app/core/sipuni.service';
import { Tag, TagsService } from '@app/core/tags.service';
import { TimezoneService } from '@app/core/timezone.service';
import { UserService } from '@app/core/user.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { each, every, find, isEmpty } from 'lodash-es';
import cloneDeep from 'lodash-es/cloneDeep';
import { DateTime } from 'luxon';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, combineLatest, from, Subject } from 'rxjs';
import { count, filter, finalize, map, switchMap, takeUntil, toArray } from 'rxjs/operators';
import { format, register } from 'timeago.js';

const key = 'personAccordion';

export interface EditableFields {
  name: string;
  phone: string;
  email: string;
  caption: string; // for groups
}

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class PersonComponent implements OnInit, OnChanges, OnDestroy {
  @Input() person: Person;
  @Input() groupChat: GroupChatInfo;
  @Input() groupChatPersonId: string;
  @Input() groupChatPersonNotes: string;
  @Input() clientId: string;
  @Input() tagList: string;
  @Input() categoryId: string;
  @Input() selectedAppealId: string;
  @Input() displayOnlineStatus = true;
  @Input() isOpen = false;
  @Input() hasAmoCRM: boolean;
  @Input() hasBitrix24: boolean;
  @Input() channelType: string;

  @Input() hasPost?: boolean;
  @Input() post?: AvitoPost;

  @Output() changedOperator: EventEmitter<string> = new EventEmitter<string>();
  @Output() changedTag: EventEmitter<string> = new EventEmitter<string>();
  @Output() changedCategory: EventEmitter<string> = new EventEmitter<string>();
  @Output() appealUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() openAppeal: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateAppeal: EventEmitter<any> = new EventEmitter<any>();

  public isPersonInfo: boolean;
  public isEdit: boolean = true;
  public noteText: string;
  public personCurrentTime: string = '';
  public setOperatorInProgress: boolean;
  public setResponsibleOperatorInProgress: boolean;
  public setTagInProgress: boolean;
  public setCategoryInProgress: boolean;
  public lastWebSessions: any[];
  public logs: any[];
  public lastWebSessionsCount: number;
  public operators: Array<OperatorData>;
  public tags: Array<Tag> = [];
  public categories: Array<Category>;
  public allOperators: Array<OperatorData>;
  public selectedOperatorId: string;
  public selectedOperator: any;
  public selectedResponsibleOperatorId: string;
  public selectedResponsibleOperator: any;
  public selectedTags: Tag[] = [];
  public selectedTagsId: string[] = [];
  public selectedCategoryId: string;
  public selectedCategory: Category;
  public personInfoHeight: any;
  public isPersonUpdateInProgress: boolean;
  public previewerImage: string;
  public showPreviewer = false;
  public translations: any;
  public dataUploadInProgress: boolean;
  public dataUploadBitrixInProgress: boolean;
  public personInfoIsVisible = false;
  public lastViewIsVisible: boolean;
  public notesLoading: boolean;
  public environment = environment;
  public isShowCustomFields = false;
  public isCustomFieldsLoaded = false;
  public stateList = {
    general: true,
    customFields: false,
    lastViewed: false,
    utm: false,
    referralUrl: false,
    assignTo: true,
    assignedTags: true,
    categories: true,
    notes: false,
    latestConversations: false,
    crms: false,
    bitrix24: false,
    history: false,
  };
  public nowText: string;
  public groupsNotes;
  public isMobile = false;
  public isTagsAvailable = this.tagsService.isTagsAvailable;
  public isShowResponsibleOperatorDropdown = false;

  private unsub$ = new Subject<void>();
  private removeInProgress: boolean;
  private profileTimeZone: string;
  private editableFields: EditableFields;
  public isCategoriesInTariff = false;
  public boundedAppeals = [];

  public iOpenHistoryTab = new BehaviorSubject<boolean>(this.stateList.history);
  public iOpenLatestConversationTab = new BehaviorSubject<boolean>(this.stateList.latestConversations);

  public get vkUrlLabel() {
    const { id } = this.person?.vk;

    const prefix = id.match(/^[0-9]+$/g) ? 'id' : '';

    return prefix + id;
  }

  get isShowLatestConversation(): boolean {
    return !this.groupChat && Boolean(this.person?.appealsHistory?.length > 0 || this.boundedAppeals.length);
  }

  get countLatestConversation(): number {
    return this.person?.appealsHistory?.length + this.boundedAppeals.length;
  }

  get personTelegramNickname(): string {
    return this.person.telegramBot?.username || this.person.telegram?.username;
  }

  get hasAccessToSettingResponsibleOperator() {
    return this.permissionService.hasAccessTo(Permission.PersonResponsibleOperatorUpdate);
  }

  get additionalFieldsCount(): number {
    const additionalFields = [
      this.person?.firstMessageAt,
      this.person?.lastOnlineAt,
      this.person?.lastMessageAt,
      this.person?.language,
      this.person?.averageRate?.rate,
      this.person?.info?.utmCampaign,
      this.person?.ip
    ];

    const filledField = additionalFields.filter(field => Boolean(field));
    return filledField.length;
  }

  constructor(
    private zone: NgZone,
    private api: PersonApiService,
    private detector: ChangeDetectorRef,
    private timezone: TimezoneService,
    private operatorService: OperatorService,
    private tagService: TagsService,
    private categoriesService: CategoriesService,
    private featureFlagsService: FeatureFlagsService,
    private userService: UserService,
    private lang: I18nService,
    private translationService: TranslateService,
    private alerts: AlertsService,
    private sanitizer: DomSanitizer,
    private apiBitrix: ApiBitrixService,
    public sipuniService: SipuniService,
    private featureAccessService: FeatureAccessService,
    private tagsService: TagsService,
    private permissionService: PermissionsService,
    private projectService: ProjectService
  ) {
    this.translations = this.lang.translateService.translations[this.lang.language];
    const stateList = localStorage.getItem(key);
    if (stateList) {
      this.stateList = JSON.parse(stateList);
    }
    this.nowText = this.translations.conversation.nowText;

    this.featureAccessService.getAccessStatus('categories')
      .subscribe((hasAccess) => {
        this.isCategoriesInTariff = hasAccess;
      });
  }

  ngOnInit() {
    this.userService.getUserData()
      .pipe(takeUntil(this.unsub$)).subscribe((user: any) => {
      if (!user) {
        return;
      }
      this.profileTimeZone = user.timezone;
    });
    this.isEdit = true;
    this.getOperatorsList();
    this.getTagList();
    this.getCategoriesList();
    this.personInfoHeight = '120px';
    this.setPlaceHolderContent();

    this.isShowCustomFields = this.projectService.isShowCustomFields

    this.stateList.customFields = false;
    this.isCustomFieldsLoaded = false;

    if (this.lang.language === 'ru') {
      const localeFunc = (number, index) => [
        ['Cейчас', 'right now'],
        ['Cейчас', 'in %s seconds'],
        ['1 мин. назад', 'in 1 minute'],
        ['%s мин. назад', 'in %s minutes'],
        ['1 ч. назад', 'in 1 hour'],
        ['%s ч. назад', 'in %s hours'],
        ['1 день назад', 'in 1 day'],
        ['%s дней назад', 'in %s days'],
        ['1 нед. назад', 'in 1 week'],
        ['%s нед. назад', 'in %s weeks'],
        ['1 мес. назад', 'in 1 month'],
        ['%s мес. назад', 'in %s months'],
        ['1 год назад', 'in 1 year'],
        ['%s лет назад', 'in %s years'],
      ][index];

      // @ts-ignore
      register('ru', localeFunc);
    }
    this.editableFields = { name: '', phone: '', email: '', caption: '' };
  }

  ngOnDestroy() {
    this.unsub$.next();
    this.unsub$.complete();
  }

  getAgo(date: string) {
    return format(date, 'ru');
  }

  ngOnChanges(changes: SimpleChanges) {
    this.zone.runOutsideAngular(() => {
      if (changes.person !== undefined && changes.person.currentValue !== null && changes.person.currentValue.date) {
        this.personCurrentTime = this.timezone.getDateWithOffset(
          changes.person.currentValue.date,
          'HH:mm',
          true,
        );
        this.setLastWebSessions();
      }

      if (changes.person && changes.person.currentValue) {
        this.person.isOnline = changes.person.currentValue.isOnline;
        this.isPersonInfo = !every(changes.person.currentValue.info, isEmpty);
        this.getPersonLog();
        this.getBoundedAppeals();

        this.selectedTagsId = changes.person.currentValue.tags;
        this.selectedTags = [];
        if (this.tags.length) {
          this.selectedTagsId.forEach(id => this.tagChanged({id}));
        }

        setTimeout(() => {
          this.getContainerHeight();
        });
        if (!this.detector['destroyed']) {
          this.detector.detectChanges();
        }
      }

      if (changes.clientId) {
        this.clientId = changes.clientId.currentValue;
        this.selectedOperatorId = changes.clientId.currentValue;
        this.operatorChanged();
      }

      if (changes.person?.currentValue) {
        this.selectedResponsibleOperatorId = changes.person.currentValue.responsibleClient;
        this.responsibleOperatorChanged();
      }

      if (changes.categoryId) {
        this.categoryId = changes.categoryId.currentValue;
        this.selectedCategoryId = changes.categoryId.currentValue;
        this.categoryChanged();
      }

      if (changes.isOpen && changes.isOpen.currentValue !== undefined) {
        this.isOpen = changes.isOpen.currentValue;
      }

      if (changes.selectedAppealId) {
        this.selectedAppealId = changes.selectedAppealId.currentValue;
        this.isEdit = false;
      }
    });
  }

  getPosition(element: any) {
    let currentTopPosition = 0;
    if (element.offsetParent) {
      do {
        currentTopPosition += element.offsetTop;
      } while (element = element.offsetParent);
      return [currentTopPosition];
    }
  }

  setPlaceHolderContent() {
    const ngSelect = document.getElementsByClassName('operator-select-wp');
    if (ngSelect.length === 0) {
      setTimeout(() => {
        this.setPlaceHolderContent();
      }, 1000);
    } else {
      const ngSelectPlaceHolderContainer = ngSelect[0].getElementsByClassName('ng-placeholder');
      const ngSelectValueContainer = ngSelect[0].getElementsByClassName('ng-value');
      // ngSelectPlaceHolderContainer[0]
      //   .setAttribute('data-content', this.translations.placeHolder.searchPerson);
      if (ngSelectValueContainer.length === 0) {
        setTimeout(() => {
          this.setPlaceHolderContent();
        }, 500);
      } else {
        ngSelectValueContainer[0].setAttribute('data-content', this.translations.placeHolder.searchPerson);
      }
    }
  }

  getContainerHeight() {
    const personInfo = document.querySelector('.personInfo');
    this.personInfoHeight = personInfo && `${personInfo.clientHeight}px`;
  }

  toggleAddInfo() {
    this.personInfoIsVisible = !this.personInfoIsVisible;
    this.detector.detectChanges();
  }

  getOperatorsList(): void {
    this.zone.runOutsideAngular(() => {
      this.operatorService.getAllOperators()
        .pipe(takeUntil(this.unsub$)).subscribe((allOperators: Array<OperatorData>) => {
        if (allOperators !== undefined) {
          each(allOperators, (operator) => {
            operator.group = this.translations.conversation.teammates;
          });
          this.allOperators = allOperators;
          this.detector.detectChanges();
        }
      });
      this.operatorService.getOperators()
        .pipe(takeUntil(this.unsub$)).subscribe((operators: Array<OperatorData>) => {
        if (operators !== undefined) {
          each(operators, (operator) => {
            operator.group = this.translations.conversation.teammates;
          });
          this.operators = operators;
          if (this.clientId) {
            this.operatorChanged();
          }
          if (this.selectedResponsibleOperatorId) {
            this.responsibleOperatorChanged();
          }
          this.detector.detectChanges();
        }
      });
    });
  }

  getTagList(): void {
    this.zone.runOutsideAngular(() => {
      this.tagService.tagList$
        .pipe(takeUntil(this.unsub$))
        .subscribe((tagList: Tag[]) => {
          if (tagList) {
            this.tags = tagList;
            if (this.selectedTagsId) {
              this.selectedTagsId.forEach(id => this.tagChanged({id}));
            }
            this.detector.detectChanges();
          }
        });
    });
  }

  getCategoriesList(): void {
    this.zone.runOutsideAngular(() => {
      this.featureFlagsService.isAvailableCategories
        .pipe(
          takeUntil(this.unsub$),
          filter((isAvailableCategories) => isAvailableCategories),
          switchMap(() => this.categoriesService.getCategories()),
        )
        .subscribe((categories) => {
          this.categories = categories;
          if (this.categoryId) {
            this.categoryChanged();
          }
        });
    });
  }

  getOperatorById(id: string) {
    return find(this.operators, (operator) => operator.id === id);
  }

  unSelectOperator() {
    if (!this.setOperatorInProgress) {
      this.selectedOperatorId = null;
      this.selectedOperator = null;
      this.setOperator();
    }
  }

  unSelectResponsibleOperator() {
    if (!this.setResponsibleOperatorInProgress) {
      this.selectedResponsibleOperatorId = null;
      this.selectedResponsibleOperator = null;
      this.setResponsibleOperator();
    }
  }

  deleteTag(event: any) {
    const id = event?.value ? event.value.id : event;
    if (!this.setTagInProgress) {
      this.selectedTagsId = this.selectedTagsId.filter((tagId) => tagId !== id);
      this.selectedTags = this.selectedTags.filter((tag) => tag.id !== id);
      this.removeTag(id);
    }
  }

  unSelectCategory() {
    if (!this.setCategoryInProgress) {
      this.selectedCategoryId = null;
      this.selectedCategory = null;
      this.setCategory();
    }
  }

  operatorChanged(withSave: boolean = false) {
    this.selectedOperator = find(this.allOperators, (operator: any) => operator.id === this.selectedOperatorId);
    if (withSave) {
      this.setOperator();
    }
  }

  toggleResponsibleOperatorDropdown() {
    this.isShowResponsibleOperatorDropdown = !this.isShowResponsibleOperatorDropdown;
    this.detector.detectChanges();
  }

  responsibleOperatorChanged(withSave: boolean = false) {
    this.selectedResponsibleOperator = find(this.allOperators, (operator: any) => operator.id === this.selectedResponsibleOperatorId);
    if (withSave) {
      this.setResponsibleOperator();
    }
  }

  searchOperator(term: string, operator: OperatorData) {
    const search = term.replace(/\s/g, '').toLowerCase();

    const lastName = operator.lastName.toLowerCase();
    const firstName = operator.name.toLowerCase();
    const unionName = lastName + firstName;
    const unionReverseName = firstName + lastName;

    return [lastName, firstName, unionName, unionReverseName].find(name => name.includes(search));
  }

  onTypeSearch() {
    this.detector.detectChanges();
  }

  tagChanged(event: any, withSave: boolean = false) {
    const addingTag= this.tags.find((tag: Tag) => tag.id === event.id);

    if (!addingTag) {
      return
    }

    this.selectedTags.push(addingTag);

    if (withSave) {
      this.setTag(event.id);
    }
  }

  categoryChanged(withSave: boolean = false) {
    this.selectedCategory = find(this.categories, (category) => category.id === this.selectedCategoryId);
    if (withSave) {
      this.setCategory();
    }
  }

  appealsTrack(index: number, appeal: AppealsListResponseDataItem) {
    return appeal.id;
  }

  setLastWebSessions() {
    this.zone.runOutsideAngular(() => {
      from(this.person.pagesHistory).pipe(
        map((history) => {
          history.humanDate = DateTime.fromISO(history.date).setZone(this.profileTimeZone)
            .setLocale(this.lang.language).toFormat('ff');
          return history;
        }),
        toArray(),
      ).subscribe((result) => this.lastWebSessions = result);

      from(this.person.pagesHistory).pipe(
        count(),
      ).subscribe((result) => this.lastWebSessionsCount = result);
    });
  }

  log(event: boolean, sectionName: string) {
    this.stateList[sectionName] = event;
    localStorage.setItem(key, JSON.stringify(this.stateList));
    this.detector.detectChanges();

    if (sectionName === 'history') {
      this.iOpenHistoryTab.next(event);
    }

    if (sectionName === 'latestConversations') {
      this.iOpenLatestConversationTab.next(event);
    }

    if (sectionName === 'customFields') {
      this.isCustomFieldsLoaded = true;
      this.detector.detectChanges();
    }
  }

  addNote(event: any) {
    if (this.stateList.notes) {
      event.stopPropagation();
    }
    this.isEdit = true;
    this.detector.detectChanges();
  }

  cancel() {
    this.noteText = '';
    this.isEdit = false;
  }

  public saveNote(personId: string): void {
    if (this.noteText.trim() === '' || !this.selectedAppealId || !this.person.id) {
      return;
    }
    const data = {
      text: this.noteText.trim(),
      appealId: this.selectedAppealId,
    };

    this.notesLoading = true;
    this.detector.detectChanges();

    this.zone.runOutsideAngular(() => {
      this.api.setPersonNote(personId, data)
        .pipe(takeUntil(this.unsub$), finalize(() => {
          this.notesLoading = false;
          this.detector.detectChanges();
        }))
        .subscribe((response: any) => {
          if (response.success) {
            this.updateAppeal.emit();
            this.noteText = '';
            this.isEdit = false;
          }
        });
    });
  }

  showConversation(appeal: any) {
    if (appeal.id === this.selectedAppealId) {
      const container = document.querySelector('.appeal-messages-container');
      const matchElement = document.querySelector(`[date-anchor="${appeal.lastActionAt}"]`);
      if (matchElement) {
        container.scroll(0, Number(this.getPosition(matchElement)));
      }
    } else {
      this.openAppeal.emit(appeal);
    }
  }

  setOperator() {
    if (this.setOperatorInProgress) {
      return;
    }
    this.setOperatorInProgress = true;
    let isSuccess = false;
    const data = {
      id: this.selectedAppealId,
      clientId: this.clientId,
      clientAppointedId: this.selectedOperatorId,
    };
    this.zone.runOutsideAngular(() => {
      this.api.appealSetClient(data)
        .pipe(takeUntil(this.unsub$))
        .subscribe((response: any) => {
          isSuccess = response.success;
          if (isSuccess) {
            this.changedOperator.emit(this.selectedOperator && this.selectedOperator.id || null);
          }
        }, () => { }, () => {
          if (!isSuccess) {
            this.selectedOperatorId = this.clientId;
            this.operatorChanged();
          }
          this.setOperatorInProgress = false;
          this.detector.detectChanges();
        });
    });
  }

  setResponsibleOperator() {
    if (this.setResponsibleOperatorInProgress) {
      return;
    }
    this.setResponsibleOperatorInProgress = true;
    const data: PersonSetResponsibleContext = {
      personId: this.person.id,
      clientAppointedId: this.selectedResponsibleOperatorId,
    };
    this.zone.runOutsideAngular(() => {
      this.api.appealSetResponsibleOperator(data)
        .pipe(takeUntil(this.unsub$))
        .subscribe((res) => {
          if (!res.success) {
            this.selectedResponsibleOperator = this.clientId;
            this.responsibleOperatorChanged();
          }
          this.setResponsibleOperatorInProgress = false;
          this.detector.detectChanges();
        });
    });
  }

  setTag(tagId: string) {
    if (this.setTagInProgress) {
      return;
    }
    this.setTagInProgress = true;
    const data = {
      personId: this.person.id,
      tagId: tagId,
    };
    this.zone.runOutsideAngular(() => {
      this.api.personSetTag(data)
        .pipe(takeUntil(this.unsub$))
        .subscribe(() => {
          this.setTagInProgress = false;
          this.detector.detectChanges();
        });
    });
  }

  removeTag(tagId: string) {
    if (this.setTagInProgress) {
      return;
    }
    this.setTagInProgress = true;
    const data = {
      personId: this.person.id,
      tagId: tagId,
    };
    this.zone.runOutsideAngular(() => {
      this.api.personRemoveTag(data)
        .pipe(takeUntil(this.unsub$))
        .subscribe(() => {
          this.setTagInProgress = false;
          this.detector.detectChanges();
        });
    });
  }

  setCategory() {
    if (this.setCategoryInProgress) {
      return;
    }
    this.setCategoryInProgress = true;
    let isSuccess = false;
    const data = {
      categoryAppointedId: this.selectedCategoryId,
    };
    this.zone.runOutsideAngular(() => {
      this.api.appealSetCategory(this.selectedAppealId, data)
        .pipe(takeUntil(this.unsub$))
        .subscribe((response: any) => {
          isSuccess = response.success;
          if (isSuccess) {
            this.changedCategory.emit(this.selectedCategory && this.selectedCategory.id || null);
          }
        }, () => { }, () => {
          if (!isSuccess) {
            this.selectedCategoryId = this.categoryId;
            this.categoryChanged();
          }
          this.setCategoryInProgress = false;
          this.detector.detectChanges();
        });
    });
  }

  saveValue(type: string): void {
    this.editableFields[type] = this.person[type];
  }

  htmlEntities(text: string): string {
    return String(text).replace(/</g, '&lt;').replace(/>/g, '&gt;');
  }

  onFieldChange(event: any, fieldName: any): void {
    let value = this.htmlEntities(event.target.innerText.trim());
    value = value.replace(/(&nbsp;)/g, '').replace(/(<br\/>)/g, '');

    // вторая строка этого условия проверяет то же самое, что и первая, только с привязкой по конкретному полю name
    // зачем? пока не понял
    // UPD: возможно все из-за преобразования toString(), но разве это поле не всегда строка?
    if (
      (value !== this.editableFields[fieldName] && (this.editableFields[fieldName] || value))
      || (fieldName === 'name' && (this.editableFields.name || value) && value !== this.editableFields.name.toString())
    ) {
      switch (fieldName) {
        case 'name':
          if (value.length > 255) {
            value = value.substr(0, 255);
          }
          break;
        case 'email':
          if (!EMAIL_REGEX.test(value)) {
            event.target.innerText = '';
            this.detector.detectChanges();
            return;
          }
          if (value.length > 40) {
            value = value.substr(0, 40);
          }
          break;
        case 'phone':
          if (!PHONE_REGEX.test(value)) {
            event.target.innerText = '';
            this.detector.detectChanges();
            return;
          }
          if (value.length > 18) {
            value = value.substr(0, 18);
          }
          break;
      }

      const oldPerson = cloneDeep(this.person);
      if (this.editableFields[fieldName]) {
        oldPerson[fieldName] = this.editableFields[fieldName];
      }
      this.person[fieldName] = value;
      this.detector.detectChanges();
      // так и не понял для чего это тут
      // const elm = document.getElementById('email');
      // const { innerHTML } = elm;
      // elm.innerHTML = '';
      // setTimeout(() => {
      //   elm.innerHTML = innerHTML;
      //   this.detector.detectChanges();
      // });

      this.doPersonUpdate(oldPerson, fieldName);
    } else {
      this.person[fieldName] = this.editableFields[fieldName];
      this.detector.detectChanges();
    }
  }

  onGroupCaptionChange(event: any) {
    let value = this.htmlEntities(event.target.innerText.trim());
    value = value.replace(/(&nbsp;)/g, '').replace(/(<br\/>)/g, '');
    value = value.substr(0, 255);
    if (value !== this.editableFields.caption && (this.editableFields.caption || value)) {
      const oldGroup = cloneDeep(this.groupChat);
      if (this.editableFields.caption) {
        oldGroup.caption = this.editableFields.caption;
      }
      this.groupChat.caption = value;

      this.groupInfoUpdate(oldGroup);
    }
  }

  onEnter(event: any) {
    event.target.blur();
    event.preventDefault();
  }

  reDrawHistory(person: Person) {
    each(this.person.appealsHistory, (appeal) => {
      if (person.id === appeal.personId) {
        appeal.personName = person.name;
      }
    });
    const copy = cloneDeep(this.person.appealsHistory);
    this.person.appealsHistory = [];
    this.person.appealsHistory = copy;
  }

  uploadDataToAmaCRM() {
    if (this.dataUploadInProgress || !this.selectedAppealId) {
      return;
    }
    this.dataUploadInProgress = true;
    this.api.uploadConversation(this.selectedAppealId)
      .pipe(takeUntil(this.unsub$))
      .subscribe((response: Response) => {
        if (!response.success) {
          this.alerts.addAlert({
            type: 'error',
            content: response.errors[0].message || '',
          });
        }
      }, () => { }, () => {
        this.dataUploadInProgress = false;
        this.detector.detectChanges();
      });
  }

  uploadDataToBitrix24() {
    if (this.dataUploadBitrixInProgress || !this.selectedAppealId) {
      return;
    }
    this.dataUploadBitrixInProgress = true;
    this.apiBitrix.uploadConversationToBitrix(this.selectedAppealId)
      .pipe(takeUntil(this.unsub$))
      .subscribe((response: Response) => {
        if (!response.success) {
          this.alerts.addAlert({
            type: 'error',
            content: response.errors[0].message || '',
          });
        }
      }, () => { }, () => {
        this.dataUploadBitrixInProgress = false;
        this.detector.detectChanges();
      });
  }

  handleUserBlock() {
    setTimeout(() => {
      this.detector.detectChanges();
    });
  }

  updateBlacklistState() {
    if (this.isPersonUpdateInProgress) {
      return;
    }
    let isSuccess = false;
    this.isPersonUpdateInProgress = true;
    this.person.banned = !this.person.banned;

    this.api.updatePersonBannedState({ personId: this.person.id }, this.person.banned)
      .pipe(takeUntil(this.unsub$))
      .subscribe((response: any) => {
        isSuccess = response.success;
      }, () => { }, () => {
        if (!isSuccess) {
          this.person.banned = !this.person.banned;
        }
        this.isPersonUpdateInProgress = false;
        this.detector.detectChanges();
      });
  }

  groupInfoUpdate(oldGroup: GroupChatInfo) {
    let isSuccess = false;
    const data = {
      caption: this.groupChat.caption,
    };

    const request = this.channelType === 'telegram' ?
      this.api.updateTelegramGroupChatName(this.selectedAppealId, data) :
      this.api.updateWhatsappGroupChatName(this.selectedAppealId, data);

    request
      .pipe(
        takeUntil(this.unsub$),
      ).subscribe((response: any) => {
      isSuccess = response.success;
      if (isSuccess && !this.detector['destroyed']) {
        this.detector.detectChanges();

        this.reDrawHistory(this.person);
        this.getPersonLog();
        this.appealUpdate.emit(this.person);
      } else {
        this.groupChat = oldGroup;
      }
    }, () => {
      if (!this.detector['destroyed']) {
        this.groupChat.caption = this.editableFields.caption;

        this.reDrawHistory(this.person);
        this.appealUpdate.emit(this.person);

        this.alerts.addAlert({
          type: 'error',
          content: this.translationService.instant('error.tryLater'),
        });
        this.detector.detectChanges();
      }
    }, () => {
      if (!this.detector['destroyed']) {
        this.detector.detectChanges();
      }
    });
  }

  doPersonUpdate(oldPerson: any, type: string) {
    let isSuccess = false;
    let data = {};

    switch (type) {
      case 'name':
        data = { name: this.person[type] };
        break;
      case 'phone':
        data = { phone: this.person[type] };
        break;
      case 'email':
        data = { email: this.person[type] };
        break;
    }

    this.api.updatePerson(this.person.id, data)
      .pipe(
        takeUntil(this.unsub$),
      ).subscribe((response: any) => {
      isSuccess = response.success;
      if (isSuccess && !this.detector['destroyed']) {
        if (response.data[type] !== undefined) {
          this.person[type] = response.data[type];
          this.detector.detectChanges();
        }
        if (type && type === 'name') {
          this.reDrawHistory(this.person);
          this.getPersonLog();
          this.appealUpdate.emit(this.person);
        }
      } else {
        this.person = oldPerson;
      }
    }, () => {
      if (!this.detector['destroyed']) {
        this.person[type] = this.editableFields[type];
        if (type && type === 'name') {
          this.reDrawHistory(this.person);
          this.appealUpdate.emit(this.person);
        }
        this.alerts.addAlert({
          type: 'error',
          content: this.translationService.instant('error.tryLater'),
        });
        this.detector.detectChanges();
      }
    }, () => {
      if (!this.detector['destroyed']) {
        this.detector.detectChanges();
      }
    });
  }

  getPersonLog() {
    if (!this.person) {
      return;
    }
    const sub = this.iOpenHistoryTab.asObservable()
      .pipe(
        takeUntil(this.unsub$),
        filter((isOpen) => isOpen),
        switchMap(() => this.api.getPersonLog(this.person.id)),
      ).subscribe((response: any) => {
        if (response.success) {
          const { data } = response;
          each(data, (item) => {
            const client = this.getOperatorById(item.clientId);
            if (client) {
              item.avatar = client.avatar || client.avatarDefault;
              item.operatorName = client.name;
            }
            item.humanDate = this.getHumanDate(item.createdAt);
          });
          this.logs = data;
        }

        if (!this.detector['destroyed']) {
          this.detector.detectChanges();
        }

        sub.unsubscribe();
      });
  }

  openPreviewer(image: string): void {
    this.previewerImage = image;
    this.showPreviewer = true;
    this.detector.detectChanges();
  }

  hideImagePreviewer(): void {
    this.showPreviewer = false;
    this.detector.detectChanges();
  }

  sanitizeUrl(url: string): SafeStyle {
    return this.zone.runOutsideAngular(() => this.sanitizer.bypassSecurityTrustStyle(`url(${url})`));
  }

  getHumanDate(date: any) {
    return DateTime.fromISO(date).setLocale(this.lang.language)
      .setZone(this.userService.user.timezone).toFormat('ff');
  }

  removeNote(note: NotesContext) {
    if (this.removeInProgress) {
      return;
    }
    const content = this.translations.deleteNoteMessage.replace('#[message]#', note.text);
    this.alerts.confirmDelete({
      title: this.translations.deleteNoteTitle,
      content,
      okButtonText: this.translations.button.delete,
      cancelButtonText: this.translations.button.cancel,
      okCallback: () => {
        this.removeInProgress = true;
        this.detector.detectChanges();

        this.zone.runOutsideAngular(() => {
          this.api.deleteNote(note.id)
            .pipe(takeUntil(this.unsub$))
            .subscribe(() => { }, () => { }, () => {
              this.removeInProgress = false;
            });
        });
      },
    });
  }

  getBoundedAppeals() {
    const sub = this.iOpenLatestConversationTab.pipe(
      takeUntil(this.unsub$),
      filter(val => val),
      switchMap(() => this.api.getBoundedPerson(this.person.id)),
    ).subscribe((res) => {
        if (res.success) {
          this.boundedAppeals = res.data;
          this.detector.detectChanges();
        }

        sub.unsubscribe()
      });
  }
}
