<div class="client-filter" *ngIf="clientGoal !== 'omnicanal'">
  <div class="select" *ngIf="!clientGoal">
    <p class="title">
      {{ 'newAccountSetup.clientGoalQuestion' | translate }}
    </p>

    <div class="variant-container">
      <button class="variant" (click)="setGoal('omnicanal')">
        <img class="emoji-icon" src="/assets/emoji/incoming-envelope.png" alt="">
        <p class="variant__label" [innerHtml]="'newAccountSetup.answerOmniCanal' | translate"></p>
      </button>

      <button class="variant" (click)="setGoal('blog')">
        <img class="emoji-icon" src="/assets/emoji/camera.png" alt="">
        <p class="variant__label" [innerHtml]="'newAccountSetup.answerBlog' | translate"></p>
      </button>
    </div>

    <p class="already-registered">
      {{ 'newRegistration.alreadyHaveAnAccount' | translate }} <a [routerLink]="['/login']">{{ 'newRegistration.alreadyHaveAnAccountLogin' | translate }}</a>
    </p>
  </div>

  <div class="answer-wrong" *ngIf="clientGoal === 'blog'">
    <img class="emoji-icon" src="/assets/emoji/pensive-face.png" alt="">
    <p class="title" [innerHtml]="'newAccountSetup.wrongAnswerTitle' | translate"></p>
    <p class="description">{{ 'newAccountSetup.wrongAnswerDescription' | translate }}</p>
    <button (click)="clientGoal = 'omnicanal'" class="answer-wrong__link">{{ 'newAccountSetup.wrongAnswerBackToRegister' | translate }}</button>
  </div>

  <app-help-needed></app-help-needed>
</div>

<div class="login-form-container" *ngIf="clientGoal === 'omnicanal'">
  <div class="form-container register-container">
    <form class="register-form" (submit)="register()"
          [formGroup]="registerForm" #registerFormForm="ngForm" novalidate>
      <div class="logo pointer" (click)="goToLanding()"></div>
      <h1>{{ 'newRegistration.registerInTeletype' | translate }}</h1>
      <app-alerts></app-alerts>
      <div class="form-group" [ngClass]="{'filled': registerForm.value.email, 'error': showError()}">
        <input appAutofocus
               [skip]="registerForm.value.email"
               type="email"
               class="ym-record-keys form-control"
               formControlName="email"
               autocomplete="off"
               [placeholder]="'newRegistration.email' | translate"
               (blur)="handleFieldBlur('registerForm', 'email')"
               required />
        <span class="floating-label">{{ 'newRegistration.email' | translate }}</span>
        <span *ngIf="registerForm.get('email').hasError('required')" class="form-group-error">
          {{ 'email.error.blank' | translate }}
        </span>
        <span *ngIf="registerForm.get('email').hasError('pattern')" class="form-group-error">
          {{ 'email.error.valid' | translate:{email: registerForm.value.email} }}
        </span>
      </div>

      <div class="public-offer" *ngIf="registerForm.get('mailingAllowed') as mailingAllowed">
        <label class="checkbox-container" [ngClass]="{'checked': mailingAllowed.value}">
          <input type="checkbox" formControlName="mailingAllowed" />
          <span [innerHTML]="'newAccountSetup.mailingAllowed' | translate"></span>
        </label>
      </div>

      <div class="public-offer" *ngIf="registerForm.get('acceptedPrivacy') as acceptedPrivacy">
        <label class="checkbox-container" [ngClass]="{'checked': acceptedPrivacy.value}">
          <input type="checkbox" formControlName="acceptedPrivacy" />
          <span [innerHTML]="'newAccountSetup.privacyCheckbox' | translate"></span>
        </label>
      </div>

      <div class="buttons-container">
        <button class="btn btn-primary with-spinner" type="submit" [disabled]="registerForm.get('acceptedPrivacy').invalid || isLoading">
          <app-spinner [inProgress]="isLoading" [btnText]="'newRegistration.registerButtonText' | translate"></app-spinner>
        </button>

        <app-elama-login-button class="elama-login"></app-elama-login-button>
      </div>

      <p class="already-registered">
        {{ 'newRegistration.alreadyHaveAnAccount' | translate }} <a [routerLink]="['/login']">{{ 'newRegistration.alreadyHaveAnAccountLogin' | translate }}</a>
      </p>
      <!--<p class="legal-info">-->
      <!--  {{ 'newRegistration.termsText' | translate }}&nbsp;<a target="_blank" href="/assets/pdf/terms.pdf">{{ 'newRegistration.terms' | translate }}</a>&nbsp;-->
      <!--  {{ 'newRegistration.and' | translate }}&nbsp;-->
      <!--  <a target="_blank" href="/assets/pdf/policy.pdf">{{ 'newRegistration.policy' | translate }}</a>-->
      <!--</p>-->
    </form>
  </div>
  <div class="bottom-bg"></div>
</div>
