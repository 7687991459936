<div (click)="onSearchClick($event)">
  <div class="search-container" [hidden]="isConversationPage"></div>
  <div class="search-container" [hidden]="!hasProject || !isConversationPage">
    <i class="teletype-icon-search"></i>
    <input type="text"
           maxLength="50"
           [placeholder]="'header.searchPlaceholder' | translate"
           [ngModel]="searchText | maxLength: 50"
           (ngModelChange)="searchHandler($event)"
           (input)="searchHandler($event.target.value)"
           (paste)="onPaste($event)"
           (focus)="onFocus()"
           (keyup.enter)="search()"
           (keyup.esc)="hide()"/>
    <div class="search-cancel" (click)="clearSearch()" [hidden]="!isVisible"></div>
  </div>
  <div class="search-box" [hidden]="!isVisible || !isConversationPage">
    <div class="box-body">
      <div>
        <div class="label _align_top">{{ 'header.whereSearchLabel' | translate }}</div>

        <div class="radio-group">
          <label class="radio-param" *ngFor="let filter of filters">
            <input type="radio" name="searchBy" [value]="filter.id" [(ngModel)]="selectedFilter">
            {{ filter.name }}
          </label>
        </div>

        <!--<ng-select class="filter-select"-->
        <!--           [items]="filters"-->
        <!--           bindLabel="name"-->
        <!--           bindValue="id"-->
        <!--           placeholder="Filter"-->
        <!--           [clearable]="false"-->
        <!--           [searchable]="false"-->
        <!--           [(ngModel)]="selectedFilter">-->
        <!--  <ng-template ng-notfound-tmp let-searchTerm="searchTerm">-->
        <!--    <div class="ng-option disabled">-->
        <!--      {{ 'crm.notFound' | translate }}-->
        <!--    </div>-->
        <!--  </ng-template>-->
        <!--</ng-select>-->
      </div>

      <div>
        <div class="label">{{ 'header.channelSearchLabel' | translate }}</div>
        <ng-select class="filter-select"
                   [items]="channels"
                   bindLabel="name"
                   bindValue="id"
                   placeholder="Filter"
                   [clearable]="false"
                   [searchable]="false"
                   [(ngModel)]="selectedChannel">
          <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
            <div class="ng-option disabled">
              {{ 'crm.notFound' | translate }}
            </div>
          </ng-template>
        </ng-select>
      </div>

      <div>
        <div class="label">{{ 'header.operatorSearchLabel' | translate }}</div>
        <ng-select class="filter-select"
                   [items]="operators"
                   bindLabel="name"
                   bindValue="id"
                   placeholder="Filter"
                   [clearable]="false"
                   [searchable]="false"
                   [(ngModel)]="selectedOperator">
          <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
            <div class="ng-option disabled">
              {{ 'crm.notFound' | translate }}
            </div>
          </ng-template>
        </ng-select>
      </div>

      <div *ngIf="isTagAvailable">
        <div class="label">{{ 'header.tags' | translate }}</div>
        <ng-select class="filter-select dropdown"
                   [items]="tags"
                   bindLabel="tag"
                   bindValue="id"
                   [placeholder]="'header.tagsNotChecked' | translate"
                   [clearable]="false"
                   [searchable]="false"
                   [multiple]="true"
                   [(ngModel)]="selectedTags">
          <ng-template ng-label-tmp let-item="item">
            <div class="tag _checked">
              <div class="tag__color" [style.background-color]="item.color"></div>
              <div class="tag__name _extra-short">{{ item.tag }}</div>
              <i class="teletype-icon-close" (click)="removeTag($event, item.id)"></i>
            </div>
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            <div class="tag">
              <div class="tag__color" [style.background-color]="item.color"></div>
              <div class="tag__name">{{ item.tag }}</div>
            </div>
          </ng-template>
          <ng-template ng-notfound-tmp let-item="item">
            <div class="ng-option disabled">
              {{ 'crm.notFound' | translate }}
            </div>
          </ng-template>
        </ng-select>
      </div>

      <div>
        <div class="label">{{ 'header.sort' | translate }}</div>
        <ng-select class="filter-select"
                   [items]="sorting"
                   bindLabel="name"
                   bindValue="id"
                   placeholder="Filter"
                   [clearable]="false"
                   [searchable]="false"
                   [(ngModel)]="selectedSorting">
          <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
            <div class="ng-option disabled">
              {{ 'crm.notFound' | translate }}
            </div>
          </ng-template>
        </ng-select>
      </div>

      <div>
        <div class="label"></div>
        <button class="btn btn-primary search" (click)="search()">
          {{ 'header.searchButtonCaption' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
